const _save_storage    = (nt, value) => localStorage.setItem(nt, JSON.stringify(value));
const _get_storage     = (itens)     => JSON.parse(localStorage.getItem(itens));
const _remove_storage  = (iten)      => localStorage.removeItem(iten);

const storage_vars = {
  language:     "language",
  cart:         "cart",
  checkout:     "checkout",
  popups:       "popups",
  coupons:      "coupons",
  
  user_auth:    "user_auth",
  chat_auth:    "chat_auth",
  notificacoes: "notificacoes",

  cookies:      "cookies",
}

module.exports = { _save_storage, _get_storage, _remove_storage, storage_vars };
