import { useNavigate  }                               from "react-router-dom";
import { _lang_checkout_pay }                         from "../../Languages/languages";

function CartIsEmpty() {
  const history                                       = useNavigate();

  return(
    <section className="services__details-area section-pt-120 section-pb-120 checkout-section">        
      <div className="center-button">
        <div className="centralizar-textos">
          <div><h1>{_lang_checkout_pay.descricao_vazio}</h1></div>
          <div onClick={() => history('/store')} className="tg-btn-1"><span>{_lang_checkout_pay.btn_loja}</span></div>
        </div>
      </div>
    </section>
  )
}

export default CartIsEmpty;
