import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const GETALLORDERS_AUTH = async (current_user) => {
  try {
    const data = new URLSearchParams();
    data.append('token',  current_user.user_token_product);
    const _get_all_orders = await AUTH.post('/routes/orders.php', data).then((response) => response.data);
    _get_all_orders.status_code_message = _lang_base.requests[_get_all_orders.status_code]
    return _get_all_orders;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default GETALLORDERS_AUTH;
