import { useState, useContext, useEffect, useRef }               from "react";
import { useParams  }                                            from "react-router-dom";
import { useNavigate }                                           from "react-router-dom";

import copy                                                      from 'clipboard-copy';
import { useSnackbar }                                           from 'notistack';
import Backdrop                                                  from '@mui/material/Backdrop';
import CircularProgress                                          from '@mui/material/CircularProgress';

import { useSynchronizeAccount }                                 from "../../Hooks/SynconizeAccount";
import { gen_coin_type }                                         from "../../Utils/StoreFunction";
import CHECK_PAYMENT                                             from "../../Utils/AUTH/CHECK_PAYMENT";
import CANCELORDER_AUTH                                          from "../../Utils/AUTH/CANCELORDER_AUTH";
import Cronometro                                                from "../../Components/Common/Cronometro";
import CartIsEmpty                                               from "../../Components/CartIsEmpty/CartIsEmpty";
import checkout_logo_pix                                         from "../../assets/img/icons/checkout_icons/checkout_pix.png";
import checkout_logo_mp                                          from "../../assets/img/icons/checkout_icons/checkout_mp.png";
import checkout_logo_stripe                                      from "../../assets/img/icons/checkout_icons/checkout_stripe.png";

import Context                                                   from "../../Provider/Context";
import { _lang_checkout_pay, _lang_base }                        from "../../Languages/languages";

const current_time    = Math.floor(Date.now() / 1000);
const CheckoutPay     = () => {
  const history                                                  = useNavigate();
  const { UserCheckout, UserInfoBase,
    ProductsCart }                                               = useContext(Context);
  const [link_status, set_link_status]                           = useState(true);
  const { order }                                                = useParams();
  const { enqueueSnackbar }                                      = useSnackbar();

  const intervalRef                                              = useRef();
  const UserInfo                                                 = UserInfoBase.Value;
  const OrderInfo                                                = UserCheckout.Value;
  const { synchronizeAccount, backdrop_open, set_backdrop_open } = useSynchronizeAccount();

  useEffect(() => {
    intervalRef.current = setInterval(check_payment, 15000);
    return () => {clearInterval(intervalRef.current);};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!OrderInfo) return <CartIsEmpty />;
  if(OrderInfo && OrderInfo.order_time_expire <= current_time) return <CartIsEmpty />;
  if (Number(OrderInfo.order_id) !== Number(order)) return <CartIsEmpty />;

  const order_properties                               = {
    order_id:             OrderInfo.order_id,
    order_link:           OrderInfo.order_link,
    order_status:         OrderInfo.order_status,
    order_time_expire:    OrderInfo.order_time_expire,
    order_total:          OrderInfo.order_total,
    order_method:         OrderInfo.order_method,
    order_currency:       OrderInfo.order_currency,
    mp_pix_qr_base64:     OrderInfo.mp_pix_qr_base64,
    mp_pix_qr_code:       OrderInfo.mp_pix_qr_code,
    encodedImage:         OrderInfo.encodedImage,
    payload:              OrderInfo.payload,
    payment_id:           OrderInfo.payment_id,
    customer:             OrderInfo.customer,
  }

  const check_payment = async () => {
    try {
      const RESULT_CHECK_PAYMENT  = await CHECK_PAYMENT({ ...UserInfo, order_id: OrderInfo.order_id });
      if(RESULT_CHECK_PAYMENT.success) {
        if(RESULT_CHECK_PAYMENT.order_details.order_status === "completed") {
          enqueueSnackbar(RESULT_CHECK_PAYMENT.status_code_message, {variant: 'success'});
          await synchronizeAccount();
          ProductsCart.Update([]);
          UserCheckout.Update([]);
          history('/ui');
          return;
        }
      }
    } catch (error) {
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  const cancel_order = async () => {
    try {
      set_backdrop_open(true);
      const RESULT_CANCELORDER_AUTH  = await CANCELORDER_AUTH({ ...UserInfo, order_id: OrderInfo.order_id });
      set_backdrop_open(false);
      if(RESULT_CANCELORDER_AUTH.success) {
        enqueueSnackbar(RESULT_CANCELORDER_AUTH.status_code_message, {variant: 'success'});
        ProductsCart.Update([]);
        UserCheckout.Update([]);
        history('/store');
        return;
      }
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  const copiar_codigo = (link, mensagem) => {
    copy(link);
    enqueueSnackbar(mensagem, { variant: 'success' });
    set_link_status(false);
  }
  
  const _component_checkout_pix_mp =
    <div id="invoice-bot">
      <div className="mp-col-md-4">
        <img decoding="async" src={checkout_logo_pix} className="mp-details-pix-img" alt="Project Official" />
        <p className="mp-details-pix-title">{_lang_checkout_pay.formas_de_pagamento.pix.como_pagar}</p>
        <ul className="mp-steps-congrats mp-pix-left">
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">1</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.info_um}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">2</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.info_dois}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">3</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.info_tres}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">4</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.infor_quatro}</p>
          </li>
        </ul>
      </div>

      <div className="mp-col-md-8 mp-text-center mp-pix-right">
        <p className="mp-details-pix-amount">
          <span className="mp-details-pix-qr">{_lang_checkout_pay.valor_a_pagar}</span>
          <span className="mp-details-pix-qr-value">{gen_coin_type(order_properties.order_total, order_properties.order_currency)}</span>
        </p>
        <p className="mp-details-pix-qr-title">{_lang_checkout_pay.formas_de_pagamento.pix.esquenei_o_qr}</p>
        <img 
          className="mp-details-pix-qr-img" 
          src={`data:image/jpeg;base64,${order_properties.mp_pix_qr_base64}`}
          alt="Pix QR"
        />
        <p className="mp-details-pix-qr-subtitle">{_lang_checkout_pay.formas_de_pagamento.pix.codigo_valido}</p>
        <div className="mp-details-pix-container">
          <p className="mp-details-pix-qr-description">{_lang_checkout_pay.formas_de_pagamento.pix.se_preferir}</p>
          <div className="mp-row-checkout-pix-container">
            <input id="mp-qr-code" value={order_properties.mp_pix_qr_code} className="mp-qr-input" readOnly/>
            <button onClick={() => copiar_codigo(order_properties.mp_pix_qr_code, _lang_checkout_pay.formas_de_pagamento.pix.msg_codigo_copiado)} className="mp-details-pix-button">
              {(link_status) ? _lang_checkout_pay.formas_de_pagamento.pix.btn_pagamento : _lang_checkout_pay.formas_de_pagamento.pix.msg_codigo_copiado}
            </button>
            <button onClick={cancel_order} className="mp-details-cancel">{_lang_checkout_pay.btn_cancell_order}</button>
          </div>
        </div>
      </div>
    </div>
  
  const _component_checkout_pix_asaas =
    <div id="invoice-bot">
      <div className="mp-col-md-4">
        <img decoding="async" src={checkout_logo_pix} className="mp-details-pix-img" alt="Project Official" />
        <p className="mp-details-pix-title">{_lang_checkout_pay.formas_de_pagamento.pix.como_pagar}</p>
        <ul className="mp-steps-congrats mp-pix-left">
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">1</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.info_um}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">2</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.info_dois}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">3</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.info_tres}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">4</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.pix.infor_quatro}</p>
          </li>
        </ul>
      </div>

      <div className="mp-col-md-8 mp-text-center mp-pix-right">
        <p className="mp-details-pix-amount">
          <span className="mp-details-pix-qr">{_lang_checkout_pay.valor_a_pagar}</span>
          <span className="mp-details-pix-qr-value">{gen_coin_type(order_properties.order_total, order_properties.order_currency)}</span>
        </p>
        <p className="mp-details-pix-qr-title">{_lang_checkout_pay.formas_de_pagamento.pix.esquenei_o_qr}</p>
        <img 
          className="mp-details-pix-qr-img" 
          src={`data:image/jpeg;base64,${order_properties.encodedImage}`}
          alt="Pix QR"
        />
        <p className="mp-details-pix-qr-subtitle">{_lang_checkout_pay.formas_de_pagamento.pix.codigo_valido}</p>
        <div className="mp-details-pix-container">
          <p className="mp-details-pix-qr-description">{_lang_checkout_pay.formas_de_pagamento.pix.se_preferir}</p>
          <div className="mp-row-checkout-pix-container">
            <input id="mp-qr-code" value={order_properties.payload} className="mp-qr-input" readOnly/>
            <button onClick={() => copiar_codigo(order_properties.payload, _lang_checkout_pay.formas_de_pagamento.pix.msg_codigo_copiado)} className="mp-details-pix-button">
              {(link_status) ? _lang_checkout_pay.formas_de_pagamento.pix.btn_pagamento : _lang_checkout_pay.formas_de_pagamento.pix.msg_codigo_copiado}
            </button>
            <button onClick={cancel_order} className="mp-details-cancel">{_lang_checkout_pay.btn_cancell_order}</button>
          </div>
        </div>
      </div>
    </div>

  const _component_checkout_stripe = 
    <div id="invoice-bot">
      <div className="mp-col-md-4">
        <img decoding="async" src={checkout_logo_stripe} className="mp-details-pix-img" alt="Project Official" />
        <p className="mp-details-pix-title">{_lang_checkout_pay.formas_de_pagamento.stripe.como_pagar}</p>
        <ul className="mp-steps-congrats mp-pix-left">
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">1</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.stripe.info_um}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">2</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.stripe.info_dois}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">3</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.stripe.info_tres}</p>
          </li>
        </ul>
      </div>

      <div className="mp-col-md-8 mp-text-center mp-pix-right">
        <div className="mp-details-pix-amount">
        <span className="mp-details-pix-qr">{_lang_checkout_pay.valor_a_pagar}</span>
          <span className="mp-details-pix-qr-value">{gen_coin_type(order_properties.order_total, order_properties.order_currency)}</span>
        </div>
        <div className="mp-details-pix-container">
          <div className="mp-row-checkout-pix-container">
            <button onClick={() => window.open(order_properties.order_link,'_blank')} className="mp-details-pix-button">{_lang_checkout_pay.formas_de_pagamento.stripe.btn_pagamento}</button>
            <button onClick={() => copiar_codigo(order_properties.order_link, _lang_checkout_pay.formas_de_pagamento.stripe.msg_codigo_copiado)} className="mp-details-pix-button">
              {(link_status) ? _lang_checkout_pay.formas_de_pagamento.stripe.btn_copiar : _lang_checkout_pay.formas_de_pagamento.stripe.msg_codigo_copiado}
            </button>
            <button onClick={cancel_order} className="mp-details-cancel">{_lang_checkout_pay.btn_cancell_order}</button>
          </div>
        </div>
      </div>
    </div>

  const _component_checkout_mp = 
    <div id="invoice-bot">
      <div className="mp-col-md-4">
        <img decoding="async" src={checkout_logo_mp} className="mp-details-pix-img" alt="Project Official" />
        <p className="mp-details-pix-title">{_lang_checkout_pay.formas_de_pagamento.mercado_pago.como_pagar}</p>
        <ul className="mp-steps-congrats mp-pix-left">
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">1</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.mercado_pago.info_um}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">2</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.mercado_pago.info_dois}</p>
          </li>
          <li className="mp-details-list">
            <p className="mp-details-pix-number-p">3</p>
            <p className="mp-details-list-description">{_lang_checkout_pay.formas_de_pagamento.mercado_pago.info_tres}</p>
          </li>
        </ul>
      </div>

      <div className="mp-col-md-8 mp-text-center mp-pix-right">
        <p className="mp-details-pix-amount">
          <span className="mp-details-pix-qr">{_lang_checkout_pay.valor_a_pagar}</span>
          <span className="mp-details-pix-qr-value">{gen_coin_type(order_properties.order_total, order_properties.order_currency)}</span>
        </p>
        <div className="mp-details-pix-container">
          <div className="mp-row-checkout-pix-container">
            <button onClick={() => window.open(order_properties.order_link,'_blank')} className="mp-details-pix-button">{_lang_checkout_pay.formas_de_pagamento.mercado_pago.btn_pagamento}</button>
            <button onClick={() => copiar_codigo(order_properties.order_link, _lang_checkout_pay.formas_de_pagamento.mercado_pago.msg_codigo_copiado)} className="mp-details-pix-button">
              {(link_status) ? _lang_checkout_pay.formas_de_pagamento.mercado_pago.btn_copiar : _lang_checkout_pay.formas_de_pagamento.mercado_pago.msg_codigo_copiado}
            </button>
            <button onClick={cancel_order} className="mp-details-pix-button">{_lang_checkout_pay.btn_cancell_order}</button>
          </div>
        </div>
      </div>
    </div>

  return (
    <>
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section className="services__details-area section-pt-120 section-pb-120 checkout-section">
        <div className="checkout-base">
          <main className="checkout-pay">
            <div id="invoiceholder">
              <div id="invoice" className="effect2">
                
              <div id="invoice-top">
                  <div className="info">
                    <h2>{_lang_checkout_pay.tempo_restante}</h2>
                    <div> <Cronometro order_time_expire={order_properties.order_time_expire} /> </div>
                  </div>
                  <div className="title">
                    <h1>{`${_lang_checkout_pay.header_pedido} #${order_properties.order_id}`}</h1>
                  </div>
                </div>
                {(Number(order_properties.order_method) === 1) && _component_checkout_pix_mp}
                {(Number(order_properties.order_method) === 2) && _component_checkout_pix_asaas}
                {(Number(order_properties.order_method) === 3) && _component_checkout_stripe}
                {(Number(order_properties.order_method) === 4) && _component_checkout_mp}
              </div>
            </div>
          </main>
        </div>
      </section>
    </>
  )
}

export default CheckoutPay;
