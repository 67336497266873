import { _lang_header_top_layout }          from "../Languages/languages";

export function time_left(time) {
  const initial_date          = new Date();
  const current_time          = Math.floor(Date.now() / 1000);
  if (current_time > time) return {"days": 0,"hours": 0,"minutes": 0};

  const final_date            = new Date(time * 1000);
  const remaining_time        = Math.abs(final_date - initial_date) / 1000;
  const days                  = Math.floor(remaining_time / 86400);
  const hours                 = Math.floor((remaining_time % 86400) / 3600);
  const minutes               = Math.floor((remaining_time % 3600) / 60);
  return { "days": days, "hours": hours, "minutes": minutes };
}

export function text_time_remaining(item) {
  if (item.days <= 0 && item.hours <= 0 && item.minutes <= 0) return _lang_header_top_layout.timer.time_is_over;
  const days    = item.days > 0 ? `${item.days} ${item.days > 1 ? _lang_header_top_layout.timer.days : _lang_header_top_layout.timer.day} ` : "";
  const hours   = item.hours > 0 ? `${item.hours} ${item.hours > 1 ? _lang_header_top_layout.timer.hours : _lang_header_top_layout.timer.hour} ` : "";
  const minutes = item.minutes > 0 ? `${item.minutes} ${item.minutes > 1 ? _lang_header_top_layout.timer.minutes : _lang_header_top_layout.timer.minute}` : "";
  return `${days}${hours}${minutes}`;
}

export function product_list_function (status) {
  if(status === true) {
    document.querySelector("body").classList.add("offCanvas__menu-visible");
    new Audio('/assets/audio/click.wav').play();
  } else {
    document.querySelector("body").classList.remove("offCanvas__menu-visible");
    new Audio('/assets/audio/remove.wav').play();
  }
}

export function product_free_informations (product) {
  let timer_end       = time_left(product.access_end);
  let timer_recharge  = time_left(product.access_recharge);

  timer_end           = text_time_remaining(timer_end);
  timer_recharge      = (timer_recharge.days <= 0 && timer_recharge.hours <= 0 && timer_recharge.minutes <= 0) ? _lang_header_top_layout.timer.can_recharge : text_time_remaining(timer_recharge);

  return { 
    game: "PROJECT - FREE", 
    access_end: timer_end, 
    access_recharge: timer_recharge
  }
}

export function product_vip_informations (product, products_info) {
  let timer_end       = time_left(product.access_end);
  timer_end           = text_time_remaining(timer_end);
  let product_info    = products_info.filter((item) => Number(item.product_id) === Number(product.product_id));

  return (product_info.length > 0) ? { 
    game: product_info[0].product_information.product_title, 
    access_end: timer_end,
  } : false;
}
