import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const DISCORDLINKED_AUTH = async (current_user) => {
  try {
    const data = new URLSearchParams();
    data.append('token',                current_user.user.user_token_product);
    data.append('access_token',         current_user.access_token);
    data.append('refresh_token',        current_user.refresh_token);
    data.append('g-recaptcha-response', current_user.rpf);
    const _discord_linked_auth = await AUTH.post('/routes/link_discord.php', data).then((response) => response.data);
    _discord_linked_auth.status_code_message = _lang_base.requests[_discord_linked_auth.status_code]
    return _discord_linked_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default DISCORDLINKED_AUTH;
