import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_user_email, verify_user_password }    from "../UserFunction";
import { verify_token_expired }                       from "../UserFunction";

export async function LOSTPASSWORD_AUTH_GET_CODE(current_user){
  try {
    verify_user_email(current_user.user_email);
  } catch (error) {
    const status_code = _lang_base.requests[error.message];
    return { status_code_message: status_code, success: false };
  }

  try {
    const data = new URLSearchParams();
    data.append('email', current_user.user_email);
    data.append('lang', _lang_base.lang_base);
    data.append('type', "get_code");
    data.append('g-recaptcha-response', current_user.rpf);
    const _lostpassword_auth_get_code = await AUTH.post('/routes/lostpassword.php', data).then((response) => response.data);
    _lostpassword_auth_get_code.status_code_message = _lang_base.requests[_lostpassword_auth_get_code.status_code]
    return _lostpassword_auth_get_code;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export async function LOSTPASSWORD_AUTH_RESET_PASSWORD(current_user) {
  try {
    verify_user_password(current_user.user_password, current_user.confi_password);
  } catch (error) {
    const status_code = _lang_base.requests[error.message];
    return { status_code_message: status_code, success: false };
  }

  try {
    const data = new URLSearchParams();
    data.append('user_password', current_user.user_password);
    data.append('user_code', current_user.user_code);
    data.append('type', "reset_password");
    data.append('g-recaptcha-response', current_user.rpf);
    const _lostpassword_auth_get_code = await AUTH.post('/routes/lostpassword.php', data).then((response) => response.data);
    _lostpassword_auth_get_code.status_code_message = _lang_base.requests[_lostpassword_auth_get_code.status_code]
    return _lostpassword_auth_get_code;
  } catch (error) {
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}
