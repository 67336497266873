const _lang_cart = {
  section_title: "Cart",
  section_pretitle: "Browse through your",
  section_info_title: "Shopping Cart",
  table_sections: {
    table_header_item: "Item",
    table_header_quantity: "Quantity",
    table_header_price: "Price",
  },
  total_orders: "Total Orders",
  btn_finalize_purchases: "Finalize Purchase",
  text_discont_message: "If you have a promotional or a discount code, please enter it right here to redeem it!",
  btn_discont: "Redeem Code",
};

module.exports = { _lang_cart };
