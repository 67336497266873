const _lang_refund_policy = {
  title_header: "Política de Reembolso",
  sub_title: "LEIA TUDO ANTES DE EFETUAR A COMPRA",
  title: "Restituição de valor parcial ou integral da compra dos nossos mods.",
  texto: "Produtos consumíveis não são reembolsáveis, tal como produtos onde é impossível saber se o usuário possui copias do software, se não concordas com este termo, não compres produtos desta espécie. Revisando: não terás direito a reembolso no caso de produtos consumíveis (keys de jogos) e em produtos onde possuis o Release (produtos digitais de download único):",
  blockquote_geral: [
    {
      cite: "Política I",
      texto: `Um computador alvo não é de propriedade de um usuário ou um usuário não recebeu o consentimento do proprietário de um computador alvo para instalar o Software comprado (disponíbilizado para apenas um HWID);`
    },
    {
      cite: "Política II",
      texto: `O computador alvo roda um sistema operacional não suportado. Os sistemas operacionais suportados estão na página de ajuda em nosso dashboard.`
    },
    {
      cite: "Política III",
      texto: `Um usuário não segue as instruções de instalação recebidas pela nossa equipe de suporte ao cliente e não aceita suporte via ferramenta de acesso remoto;`
    },
    {
      cite: "Política IV",
      texto: `Um usuário não aceita assistência técnica de nenhuma espécie;`
    },
    {
      cite: "Política V",
      texto: `Um usuário pretendia usar os produtos em mais de um computador;`
    },
    {
      cite: "Política VI",
      texto: `Razões pessoais (Eu mudei de ideia, eu fiz uma compra por engano, o Software não foi usado, não desejo aguardar as atualizações, etc.);`
    },
    {
      cite: "Política VII",
      texto: `Sem acesso ao computador alvo (Perdi/estragou meu computador, não vou mais usar, etc.);`
    },
    {
      cite: "Política VIII",
      texto: `Um computador alvo não possui conexão com a Internet;`
    },
    {
      cite: "Política IX",
      texto: `O computador alvo de um usuário não está de acordo com os requisitos de compatibilidade do produto;`
    },
    {
      cite: "Política X",
      texto: `O Software foi desvinculado ou danificado por um software antivírus ou pelo proprietário do dispositivo alvo;`
    },
    {
      cite: "Política XI",
      texto: `Um computador alvo perdeu a conexão com a assinatura do produto devido à restauração de fábrica ou atualização do sistema operacional.`
    },
    {
      cite: "Política XII",
      texto: `O usuário recebeu banimentos de jogo, ou qualquer outro banimento não referente ao Valve Antimod;`
    },
    {
      cite: "Política XIII",
      texto: `O usuário recebeu banimeto porém não pode provar sua relação com o uso do produto;`
    },
    {
      cite: "Política XVI",
      texto: `O usuário se nega a fornecer mais provas em caso de suspeita de provas fraudulentas;`
    }
  ],
  textos_importantes: [
    {
      titulo: "RELACIONADO A ESTORNO",
      texto: `Em caso de um estorno por uma empresa de cartão de crédito (ou ação similar de outro provedor de pagamentos permitido por nós) relacionado à sua compra de qualquer plano de assinatura, concordas que nós podemos suspender o acesso a qualquer uma das contas que tiveres connosco.
      Em caso de emissão do reembolso, por favor note que para cada transação poderás ser descontato uma taxa do valor reembolsado para cobrir as taxas cobradas por bancos e empresas de processamento de pagamentos.
      Nós não aceitamos solicitações de reembolso pelo Chat ou por qualquer outro meio que não seja o aqui referido.
      Não aceitamos solicitações de reembolso que faltem com um ou mais dos dados aqui solicitados, sendo passíveis de não serem respondidas.
      A decisão do reembolso será tomada dentro de sete dias úteis.`,
    },
    {
      titulo: "RELACIONADO A SOLICITAÇÃO",
      texto: `Para solicitar o reembolso esteja ciente dos Termos e Condições de Uso.
      O reembolso só é será fornecido se provado devidamente a vinculação do produto com o banimentos por detecção instantânea do Anti-mod. Quaisquer outro tipo de banimento, não é cabível de reembolso, bem como após o termino do periodo de garantia do produto o reembolso já não poderá mais ser solicitado, devido ao usuário já ter usufruido do mesmo que por sua vez cumpriu com os termos de venda do produto.`,
    },
    {
      titulo: "RELACIONADO AO DESCUMPRIMENTO",
      texto: `Descumprir nossos termos resultará em um banimento permanente de sua conta, caso deseje efetuar o reembolso parcial você terá uma multa de 1/3 do valor, caso abra uma reclamação nos intermediadores nós iremos contesta-la com nossas provas e você perderá o direito do reembolso parcial.`,
    },
    {
      titulo: "RELACIONADO A PROVA",
      texto: `Quando um mod é conhecido pelo sistema, o antimod causa ban instantâneo no momento da ativação. É esta, e somente esta a garantia que é fornecida e passível de reembolso se devidamente comprovada.`,
    },
    {
      titulo: "RELACIONADO AO PROCEDIMENTO",
      texto: `Enviar um email para suporte@projectoficial.com contendo o link ou o arquivo de vídeo da prova, tal como os anexos e dados da solicitação, como nome completo, endereço de email do pagamento e do cadastro do site, data da compra, produto que está solicitando o reembolso. Outras provas podem ser anexadas e favorecer o seu caso de reembolso.`,
    },
  ],
  blockquote_banimento: [
    {
      cite: "Política Banimentos I",
      texto: `Criar uma conta nova, realizar a injeção e provar que a ativação resulta em banimento. Todos estes passos devidamente gravados sem interrupções ou cortes no vídeo.`
    },
    {
      cite: "Política Banimentos II",
      texto: `Sempre gravar a ativação do mod, seja por software de gravação de tela ou pelo celular.`
    }
  ],
  texto_final: `Apenas os paragrafos anteriores são aceitos como provas, demais arquivos podem ser considerados auxiliares no caso, mas é necessário que o item I ou II estejam contidos na solicitação.
  Esteja ciente de que não enviar estas informações na solicitação, pode ocasionar na invalidação da sua solicitação, onde a mesma nem se quer pode ser respondida.`,
  breadcrumb_item: "Home"
}

module.exports = { _lang_refund_policy };
