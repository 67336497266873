import { useState, useContext, useEffect }        from "react";
import { useNavigate  }                           from "react-router-dom";

import LoadingComponent                           from "../../Components/Common/LoadingComponent";
import PreviewProductTable                        from "../../Components/PreviewProductTable/PreviewProductTable";
import CreateSvg                                  from "../../Components/Common/CreateSvg";

import Context                                    from "../../Provider/Context";

/* IMPORT LANGUAGE */
import { _lang_ui }                               from "../../Languages/languages";

function UI() {
  const { UserInfoBase, Products }                = useContext(Context);
  const UserInfo                                  = UserInfoBase.Value;

  const history                                   = useNavigate();

  const user_infos                                = UserInfo;
  const product_info                              = [...Products.vip, ...Products.free].filter((item) => (item.product_category_id === 1 && item.product_enable_ui === 1))

  const [filter_name, set_filter_name]            = useState("");
  const [category_product, set_category_product]  = useState("0");
  const [card_table, set_card_table]              = useState(0);
  const [pos_card_table, set_pos_card_table]      = useState({top: "500px",left: "200px"});
  const [loading_products, set_loading_products]  = useState(true);

  const mouse_card_fora                           = () => 
    document.querySelector(".cd-single-point").classList.remove('is-open');

  const mouse_card_cima                           = (elemento) => {
    const screen_size              = { width: window.innerWidth, height: window.innerHeight };
    if(screen_size.width <= 882) return;
    const { dataset, offsetWidth } = elemento.target;
    const { top, left }            = elemento.currentTarget.getBoundingClientRect();
    const distance_left            = left + offsetWidth + 5;
    const isLeft                   = distance_left + 340 >= screen_size.width;
    
    if (top - 100 <= 0 || top + 100 >= screen_size.height) return;
    set_card_table(dataset.mod);

    const selectedPoint = document.querySelector(".cd-single-point");
    const selectInfo    = document.querySelector(".cd-more-info");
  
    if (!selectedPoint || !selectInfo) return;
  
    selectInfo.classList.remove('cd-left', 'cd-right');
    selectInfo.classList.add(isLeft ? 'cd-left' : 'cd-right');
    set_pos_card_table({ top: `${top + window.scrollY}px`, left: `${isLeft ? left - 278 : distance_left}px` });
    selectedPoint.classList.add('is-open');
  };

  const category_list                             = [
    {
      title: _lang_ui.category_list.all_products,
      value: "0",
    },
    {
      title: _lang_ui.category_list.favorites_products,
      value: "1",
    },
    {
      title: _lang_ui.category_list.recenty_products,
      value: "2",
    },
    {
      title: _lang_ui.category_list.mods_vip,
      value: "3",
    },
    {
      title: _lang_ui.category_list.mods_free,
      value: "4",
    },
  ]

  let filter_products                             = product_info.filter((item) => (item.product_information !== undefined 
    && item.product_information.product_title !== undefined
    && item.product_information.product_title.toLowerCase().includes(filter_name)));

  switch(category_product) {
    case "0":
      filter_products = product_info.filter((item) => (item.product_information !== undefined 
        && item.product_information.product_title !== undefined 
        && item.product_information.product_title.toLowerCase().includes(filter_name)));
      break;
    case "1":
      filter_products = filter_products.filter((item) => (UserInfo) ? user_infos.user_game_folowers.includes(item.product_id) : item);
      break;
    case "2":
      filter_products = filter_products.slice().sort((a, b) => new Date(b.product_date) - new Date(a.product_date));
      break;
    case "3":
      filter_products = filter_products.filter((item) => (Number(item.product_type_id) === 2));
      break;
    case "4":
      filter_products = filter_products.filter((item) => (Number(item.product_type_id) === 1));
      break;
    default:
      filter_products = product_info.filter((item) => (item.product_information !== undefined 
        && item.product_information.product_title !== undefined
        && item.product_information.product_title.toLowerCase().includes(filter_name)));
      break;
  }

  useEffect(() => { setTimeout(() => set_loading_products(false) , 1000); }, []);
  
  const component_gen_filter                      = category_list.map((item, index) => 
    <option key={`catgory_list_${index}`} value={item.value}>{item.title}</option>);
  
  const component_loading_product_list            = filter_products.map((_item, index) =>
    <div key={`loading_${index}`} style={{display: (loading_products) ? "block" : "none"}} className="d-steam-card-wrapper">
      <div className="d-steam-card-v2">
        <LoadingComponent />
      </div>
    </div>
  )
  
  const gen_products_list                         = filter_products.map((item, index) =>
    <div style={{display: (loading_products) ? "none" : "block"}} className="d-steam-card-wrapper" key={index} onClick={() => history(`${item.product_slug}`)}>
      <div 
        onMouseOver={mouse_card_cima}
        onMouseOut={mouse_card_fora}
        className="d-steam-card anim"
        style={{"backgroundImage": `url('${item.product_base_ui_img}')`,"--delay:": ".4s;"}} 
        data-mod={item.product_id}
      >
      </div>
    </div>
  )
  
  return(
    <>
      <div className="container profile"> 
        <div className="content-grid">
          <div className="section-filters-bar section-filters-bar-v2 section-filters-bar-no-shadow v1">

            <div className="section-filters-bar-actions section-filters-bar-actions-v2">
              <div className="form form-v2">
                <div className="form-input small with-button">
                  <input placeholder={_lang_ui.input_scan_mods} type="text"
                    value={filter_name} 
                    onChange={({target}) => set_filter_name(target.value.toLowerCase())}
                  />
                </div>
              </div>
              <div className="form-select form-select-v3 small">
                <select value={category_product} 
                  id="items-filter-category" 
                  name="items_filter_category" 
                  onChange={({ target }) => set_category_product(target.value)}
                >
                  {component_gen_filter}
                </select>
                  <CreateSvg svgname="svg_small_arrow" svgclassname="form-select-icon form-select-icon-v2 icon-small-arrow" />
              </div>
            </div>
          </div>
          <div className="grid grid-remove grid-5-5-5 centered">{component_loading_product_list}{gen_products_list}</div>
        </div>
      </div>
      <div className="cd-single-point modal-game" style={pos_card_table}>
        <PreviewProductTable product_id={ card_table } product_info={product_info} />
      </div>
    </>
  )
}

export default UI;
