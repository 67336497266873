import { Fragment, useEffect }             from "react";
import { useNavigate }                     from "react-router-dom";

import Footer                              from "../../Components/Footer/Footer";
import EffectAutoEscrita                   from "../../Components/Common/EffectAutoEscrita";

/* IMPORT IMAGES */
import TeamDetailsBG                       from "../../assets/img/bg/team_details_bg.jpg";
import BackgroundImagem                    from "../../assets/img/bg/header.jpg";
import HeaderIcon                          from "../../assets/img/others/breadcrumb_img01.png";
import HeaderImage                         from "../../assets/img/others/header_termos01.jpg";

/* IMPORT LANGUAGE */
import { _lang_refund_policy, _lang_base } from "../../Languages/languages";

function RefundPolicy() {
  const history = useNavigate();
  useEffect(() => {window.scrollTo({top: 0,behavior: 'smooth'});}, []);
  
  return (
    <>
      <main className="main--area">
        <section className="breadcrumb-area" style={{ backgroundImage: `url(${BackgroundImagem})` }}>
            <div className="container">
                <div className="breadcrumb__wrapper">
                    <div className="row">
                        <div className="col-xl-6 col-lg-7">
                            <div className="breadcrumb__content">
                                <h2 className="title">{_lang_refund_policy.title_header}</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><div onClick={() => history('/')}>{_lang_refund_policy.breadcrumb_item}</div></li>
                                        <li className="breadcrumb-item active" aria-current="page">{_lang_refund_policy.title_header}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-5 position-relative d-none d-lg-block">
                            <div className="breadcrumb__img">
                                <img src={HeaderIcon} alt={_lang_base.web_site_name} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="services__details-area section-pt-120 section-pb-120" style={{ backgroundImage: `url(${TeamDetailsBG})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="team__details-img">
                            <img src={HeaderImage} alt={_lang_base.web_site_name} />
                            <svg width="145" height="66" viewBox="0 0 145 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.95">
                                    <path d="M11.94 56.1H0V65.38H11.94V56.1Z" fill="currentcolor"/>
                                    <path d="M30.81 56.1H18.87V65.38H30.81V56.1Z" fill="currentcolor"/>
                                    <path d="M49.37 56.1H37.47V65.38H49.37V56.1Z" fill="currentcolor"/>
                                    <path d="M68.25 56.1H56.34V65.38H68.25V56.1Z" fill="currentcolor"/>
                                    <path d="M87.81 56.1H75.91V65.38H87.81V56.1Z" fill="currentcolor"/>
                                    <path d="M95.12 56.1H107.03V65.3799H95.12V56.1Z" fill="currentcolor"/>
                                    <path d="M125.94 56.1H114V65.38H125.94V56.1Z" fill="currentcolor"/>
                                    <path d="M144.5 56.1H132.56V65.38H144.5V56.1Z" fill="currentcolor"/>
                                    <path d="M11.94 37.1H0V46.38H11.94V37.1Z" fill="currentcolor"/>
                                    <path d="M30.81 37.1H18.87V46.38H30.81V37.1Z" fill="currentcolor"/>
                                    <path d="M49.37 37.1H37.47V46.38H49.37V37.1Z" fill="currentcolor"/>
                                    <path d="M68.25 37.1H56.34V46.38H68.25V37.1Z" fill="currentcolor"/>
                                    <path d="M87.81 37.1H75.91V46.38H87.81V37.1Z" fill="currentcolor"/>
                                    <path d="M95.12 37.1H107.03V46.3799H95.12V37.1Z" fill="currentcolor"/>
                                    <path d="M125.94 37.1H114V46.38H125.94V37.1Z" fill="currentcolor"/>
                                    <path d="M144.5 37.1H132.56V46.38H144.5V37.1Z" fill="currentcolor"/>
                                    <path d="M11.94 18.53H0V27.85H11.94V18.53Z" fill="currentcolor"/>
                                    <path d="M30.81 18.53H18.87V27.85H30.81V18.53Z" fill="currentcolor"/>
                                    <path d="M49.37 18.53H37.47V27.85H49.37V18.53Z" fill="currentcolor"/>
                                    <path d="M68.25 18.53H56.34V27.85H68.25V18.53Z" fill="currentcolor"/>
                                    <path d="M87.81 18.53H75.91V27.85H87.81V18.53Z" fill="currentcolor"/>
                                    <path d="M95.12 18.53H107.03V27.85H95.12V18.53Z" fill="currentcolor"/>
                                    <path d="M125.94 18.53H114V27.85H125.94V18.53Z" fill="currentcolor"/>
                                    <path d="M144.5 18.53H132.56V27.85H144.5V18.53Z" fill="currentcolor"/>
                                    <path d="M11.94 0H0V9.28H11.94V0Z" fill="currentcolor"/>
                                    <path d="M30.81 0H18.87V9.28H30.81V0Z" fill="currentcolor"/>
                                    <path d="M49.37 0H37.47V9.28H49.37V0Z" fill="currentcolor"/>
                                    <path d="M68.25 0H56.34V9.28H68.25V0Z" fill="currentcolor"/>
                                    <path d="M86.81 0H74.91V9.28H86.81V0Z" fill="currentcolor"/>
                                    <path d="M94.12 6.10352e-05H106.03V9.27997H94.12V6.10352e-05Z" fill="currentcolor"/>
                                </g>
                            </svg>
                        </div>
                        <div className="team__details-content">
                            <span className="sub-title"><EffectAutoEscrita texto={_lang_refund_policy.sub_title} /></span>
                            <h2 className="title">{_lang_refund_policy.title}</h2>
                            <p>{_lang_refund_policy.texto}</p>
                            {
                              _lang_refund_policy.blockquote_geral.map((item, index) => 
                              <blockquote key={index} className="team__details-quote">
                                  <cite>{item.cite}</cite>
                                  <p>{item.texto}</p>
                              </blockquote>)
                            }
                            {
                              _lang_refund_policy.textos_importantes.map((item, index) => 
                              <Fragment key={`termosDePrivacidade-${index}`}>
                                <h2>{item.titulo}</h2>
                                <p >{item.texto}</p>
                              </Fragment>)
                            }
                            {
                              _lang_refund_policy.blockquote_banimento.map((item, index) => 
                              <blockquote key={index} className="team__details-quote">
                                  <cite>{item.cite}</cite>
                                  <p>{item.texto}</p>
                              </blockquote>)
                            }
                            <p>{_lang_refund_policy.texto_final}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default RefundPolicy;
