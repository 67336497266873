import { useState, useEffect }            from "react";
import { useNavigate  }                   from "react-router-dom";

import Footer                             from "../../Components/Footer/Footer";
import EffectAutoEscrita                  from "../../Components/Common/EffectAutoEscrita";

/* IMPORT IMAGES */
import ServiceImg01                       from "../../assets/img/others/services_img01.jpg";
import ServiceImg02                       from "../../assets/img/others/services_img02.jpg";
import ServiceImg03                       from "../../assets/img/others/services_img03.jpg";
import ServiceImg04                       from "../../assets/img/others/services_img04.jpg";
import BackgroundImagem                   from "../../assets/img/bg/header02.jpg";
import HeaderIcon                         from "../../assets/img/others/breadcrumb_img03.png";

/* IMPORT LANGUAGE */
import { _lang_terms_of_use, _lang_base } from "../../Languages/languages";

function TermsOfUse() {
  const history = useNavigate();
  useEffect(() => window.scrollTo({top: 0,behavior: 'smooth'}), []);
  
  const todasImages = [
    ServiceImg01, ServiceImg02, ServiceImg03, ServiceImg04, ServiceImg01,
    ServiceImg02, ServiceImg03, ServiceImg04, ServiceImg01, ServiceImg02,
    ServiceImg03, ServiceImg04, ServiceImg01
  ]

  const [imageSelected, atuImageSelected] = useState(0);

  return (
    <>
      <main className="main--area">
        <section className="breadcrumb-area" style={{ backgroundImage: `url(${BackgroundImagem})` }}>
          <div className="container">
            <div className="breadcrumb__wrapper">
              <div className="row">
                <div className="col-xl-6 col-lg-7">
                  <div className="breadcrumb__content">
                    <h2 className="title">{_lang_terms_of_use.title}</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <div onClick={()=> history('/')}>{_lang_terms_of_use.breadcrumb_item}</div>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{_lang_terms_of_use.title}</li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 position-relative d-none d-lg-block">
                  <div className="breadcrumb__img">
                    <img src={HeaderIcon} alt={_lang_base.web_site_name} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="faq__content-wrap">
                  <div className="section__title text-start">
                    <span className="sub-title tg__animate-text">
                      <EffectAutoEscrita texto={_lang_terms_of_use.sub_title} />
                    </span>
                    <h3 className="title">{_lang_terms_of_use.title_sub}</h3>
                    <p>{_lang_terms_of_use.context}</p>
                  </div>
                  <div className="faq__wrapper">
                    <div className="accordion" id="accordionExample"> {_lang_terms_of_use.informacacoes.map((item, index) => <div key={index} className="accordion-item">
                        <h2 className="accordion-header" onClick={()=> atuImageSelected(index)} id={"regra" + index}> 
                        <button className={`accordion-button ${(imageSelected===index) ? "" : "collapsed"}`} type="button" aria-expanded="false" aria-controls={"collapse" + index}>
                            <span className="count">{index + 1}</span>{item.titulo} </button>
                        </h2>
                        <div id={"collapse" + index} className={`accordion-collapse collapse ${(imageSelected === index) ? "show" : ""}`} aria-labelledby={"regra" + index}>
                          <div className="accordion-body">{item.texto}</div>
                        </div>
                      </div> )} </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="services__images" id="imgs-regras"> {todasImages.map((item, index) => <div key={index} className={(imageSelected===index) ? 'services__images-item active' : 'services__images-item' }>
                    <img src={item} alt={_lang_base.web_site_name} />
                    <div onClick={()=>window.open('https://discord.gg/S4PM5vcjMW','_blank')} className="services__link"> <i className="flaticon-next"></i>
                    </div>
                  </div>)} </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default TermsOfUse;
