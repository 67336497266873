import { useEffect, useState } from "react";

const textosVerify = {injetar: ["Injetar", "Inject"]}

function ButtonActive(props) {
  const [retornoVerificador, atuRetornoVerificador] = useState(props.tipo);
  const [textoInject, atuTextoInject]               = useState(props.texto);

  useEffect(() => {
    const button_injet = document.querySelector("#button-inject");

    button_injet.classList.remove("loading");
    button_injet.classList.remove("complete");
    button_injet.classList.remove("error");
    button_injet.classList.remove("ready");

    if(props.tipo === "carregando" || props.tipo === "error" || props.tipo === "success" ) button_injet.classList.add("injetando");
    if(textosVerify.injetar.includes(props.tipo))    setTimeout(() => button_injet.classList.add("ready"), 20);
    else if(props.tipo === "carregando") button_injet.classList.add("loading");
    else if(props.tipo === "error")      setTimeout(() => button_injet.classList.add("error"), 20);
    else if(props.tipo === "success")    setTimeout(() => button_injet.classList.add("complete"), 20);

    atuRetornoVerificador(props.tipo);
    atuTextoInject(props.texto);
    
  }, [props.texto, props.tipo]);

  return(
    <>
      {
        (retornoVerificador === "carregando") && 
          <div className="inject-notificacao loadingMessage">
            {textoInject}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17">
              <circle className="loadingCircle" cx="2.2" cy="10" r="1.6"/>
              <circle className="loadingCircle" cx="9.5" cy="10" r="1.6"/>
              <circle className="loadingCircle" cx="16.8" cy="10" r="1.6"/>
            </svg>
          </div>
      }
      {
        (textosVerify.injetar.includes(retornoVerificador)) && 
        <div className="inject-notificacao submitMessage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12.2">
            <polyline stroke="currentColor" points="2,7.1 6.5,11.1 11,7.1 "/>
            <line stroke="currentColor" x1="6.5" y1="1.2" x2="6.5" y2="10.3"/>
          </svg>
          <span className="button-text">
            {textoInject.split("").map((item, index) => 
              <span key={index} className={"char" + index} style={{"--d": `${index * 30}ms`,   "--dr": `${(textoInject.split("").length - index - 1) * 30}ms`}}>{item}</span>
            )}
          </span>
        </div>
      }
      {
        (retornoVerificador === "error") && 
        <div className="inject-notificacao errorMessage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 25" stroke="currentColor">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          <span className="button-text">
            {textoInject.split("").map((item, index) => 
                <span key={index} className={"char" + index} style={{"--d": `${index * 30}ms`,   "--dr": `${(textoInject.split("").length - index - 1) * 30}ms`}}>{item}</span>
              )}
          </span>
        </div>
      }
      {
        (retornoVerificador === "success") && 
        <div className="inject-notificacao successMessage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12">
            <polyline stroke="currentColor" points="1.4,5.8 5.1,9.5 11.6,2.1 "/>
          </svg>
          <span className="button-text">
            {textoInject.split("").map((item, index) => 
                <span key={index} className={"char" + index} style={{"--d": `${index * 30}ms`,   "--dr": `${(textoInject.split("").length - index - 1) * 30}ms`}}>{item}</span>
              )}
          </span>
        </div>
      }
    </>
  )
}

export default ButtonActive;
