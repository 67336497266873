import { useContext, useEffect, useState, useRef }    from "react";
import { useNavigate }                                from "react-router-dom";
import ReCAPTCHA                                      from "react-google-recaptcha";

import { useSnackbar }                                from 'notistack';
import Backdrop                                       from '@mui/material/Backdrop';
import CircularProgress                               from '@mui/material/CircularProgress';

import CreateSvg                                      from "../../Components/Common/CreateSvg";
import CartIsEmpty                                    from "../../Components/CartIsEmpty/CartIsEmpty";

import Context                                        from "../../Provider/Context";
import { gen_coin_type, total_values, type_discont, 
  remove_coupon_discont }                             from "../../Utils/StoreFunction";

/* IMPORT IMAGES */
import marketplace_icon                               from "../../assets/img/icons/market_place/marketplace_icon.png";

/* IMPORT LANGUAGE */
import { _lang_cart, _lang_base }                     from "../../Languages/languages";
import CHECKCOUPON_AUTH                               from "../../Utils/AUTH/CHECK_COUPON";

const current_time    = Math.floor(Date.now() / 1000);
function Cart() {
  const history                                       = useNavigate();
  const { ProductsCart, UserCheckout, 
    UserInfoBase, Coupons }                           = useContext(Context);

  const products_cart                                 = ProductsCart.Value;
  const OrderInfo                                     = UserCheckout.Value;
  const UserInfo                                      = UserInfoBase.Value;
  const CouponsInfo                                   = Coupons.Value;

  const value_total                                   = total_values(products_cart, CouponsInfo);
  const value_total_no_discont                        = total_values(products_cart, undefined);
  
  const recaptchaRef                                  = useRef();
  const { enqueueSnackbar }                           = useSnackbar();
  const [backdrop_open, set_backdrop_open]            = useState(false);
  const [coupon_code, set_coupon_code]                = useState("");

  useEffect(() => {
    if(OrderInfo && OrderInfo.order_time_expire >= current_time) history(`/checkout/${OrderInfo.order_id}`);
  }, [OrderInfo, history]);

  if(OrderInfo && OrderInfo.order_time_expire >= current_time) return <></>;
  if(products_cart.length <= 0) return <CartIsEmpty />;
  const remove_item              = (item_current) => ProductsCart.Update(ProductsCart.Value.filter((item) => item.product_id !== item_current));
  const add_item_cart_and_remove = (product_info, verify) => {
    if(product_info.product_amount <= 1 && !verify) return;
    if(verify) ProductsCart.Update(ProductsCart.Value.map((item) => (item.product_id === product_info.product_id) ? {...item, product_amount: item.product_amount + 1 } : item))
    else ProductsCart.Update(ProductsCart.Value.map((item) => (item.product_id === product_info.product_id) ? {...item, product_amount: item.product_amount - 1 } : item));
  }

  const add_coupon = async () => {
    if(!UserInfo) {
      enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
      return history("/login");
    }

    if(CouponsInfo !== undefined) {
      enqueueSnackbar(_lang_base.requests["921"], {variant: 'error'});
      return;
    }

    try {
      set_backdrop_open(true);
      const rpf                          = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_CHECKOUTCOUPON_AUTH   = await CHECKCOUPON_AUTH({ ...UserInfo, coupon_code, rpf });
      recaptchaRef.current.reset();
      set_backdrop_open(false);

      if(RESULT_CHECKOUTCOUPON_AUTH.success) {
        enqueueSnackbar(RESULT_CHECKOUTCOUPON_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_CHECKOUTCOUPON_AUTH.status_code_message;
        delete RESULT_CHECKOUTCOUPON_AUTH.success;
        delete RESULT_CHECKOUTCOUPON_AUTH.status_code;

        Coupons.Update(RESULT_CHECKOUTCOUPON_AUTH.coupon_info);
        return;
      }
      enqueueSnackbar(RESULT_CHECKOUTCOUPON_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  const component_products_cart = products_cart.map((item, index) => 
    <div key={index} className="table-row medium">
      <div className="table-column">
        <div className="product-preview tiny">
          <figure className="product-preview-image liquid" style={{
            background: `url('${item.product_base_store_img}') center center / cover no-repeat`, width: "50px", height: "70px"}} />
          <div className="product-preview-info">
            <p className="product-preview-title">{item.product_information.product_title}</p>
            <p className="product-preview-category digital">{item.product_information.product_category}</p>
          </div>
        </div>
      </div>
      <div className="table-column padded-left"></div>
      <div className="table-column padded-left">
        <div className="form-counter">
          <p className="form-counter-value">{item.product_amount}</p>
          <div className="form-counter-controls">
            <div className="form-counter-control form-counter-control-increase" onClick={() => add_item_cart_and_remove(item, true)}>
              <CreateSvg svgname="svg_small_arrow" svgclassname="form-counter-icon icon-small-arrow" />
            </div>
            <div className="form-counter-control form-counter-control-decrease" onClick={() => add_item_cart_and_remove(item, false)}>
              <CreateSvg svgname="svg_small_arrow" svgclassname="form-counter-icon icon-small-arrow" />
            </div>
          </div>
        </div>
      </div>
      <div className="table-column centered padded-left">
        <p className="price-title">{gen_coin_type(item.product_information.product_discont, _lang_base.currency)} x {item.product_amount}</p>
      </div>
      <div className="table-column padded-big-left">
        <div className="table-action" onClick={() => remove_item(item.product_id)}>
          <CreateSvg svgname="svg_delete" svgclassname="icon-delete" />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
      />
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="profile container">
        <div className="content-grid">
          <div className="section-banner">
            <img className="section-banner-icon" src={marketplace_icon} alt={_lang_base.web_site_name} />
            <p className="section-banner-title">{_lang_cart.section_title}</p>
          </div>
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">{_lang_cart.section_pretitle}</p>
              <h2 className="section-title">{_lang_cart.section_info_title} <span className="highlighted">{products_cart.length}</span></h2>
            </div>
          </div>
          <div className="grid grid-9-3 small-space">
            <div className="grid-column">
              <div className="table-wrap">
                <div className="table table-cart split-rows">
                  <div className="table-header">
                    <div className="table-header-column">
                      <p className="table-header-title">{_lang_cart.table_sections.table_header_item}</p>
                    </div>
                    <div className="table-header-column padded-left"></div>
                    <div className="table-header-column padded-left">
                      <p className="table-header-title">{_lang_cart.table_sections.table_header_quantity}</p>
                    </div>
                    <div className="table-header-column centered padded-left">
                      <p className="table-header-title">{_lang_cart.table_sections.table_header_price}</p>
                    </div>
                    <div className="table-header-column padded-big-left"></div>
                  </div>
                  <div className="table-body same-color-rows">{component_products_cart}</div>
                </div>
              </div>
            </div>
            
            <div className="grid-column">
              <div className="sidebar-box margin-top">
                <p className="sidebar-box-title">{_lang_cart.total_orders}</p>
                <div className="sidebar-box-items">
                  <p className="price-title big">{gen_coin_type(value_total, _lang_base.currency)}</p>   

                  <div className="totals-line-list">
                    <div className="totals-line">
                      <p className="totals-line-title">Cart ({products_cart.length})</p>
                      <p className="price-title">{gen_coin_type(value_total_no_discont, _lang_base.currency)}</p>
                    </div>
                    {(CouponsInfo !== undefined) && 
                    <div className="totals-line"> 
                      <p className="totals-line-title totals-line-title-two"><span onClick={remove_coupon_discont}><CreateSvg svgname="svg_delete" svgclassname="icon-delete" /></span> Code: {CouponsInfo.coupon_code}</p>
                      <p className="price-title">-{type_discont(CouponsInfo)}</p>
                    </div>}
                    <div className="totals-line">
                      <p className="totals-line-title">Total</p>
                      <p className="price-title">{gen_coin_type(value_total, _lang_base.currency)}</p>
                    </div>
                  </div>    
                            
                  <div className="button primary" onClick={() => history("/checkout")}>{_lang_cart.btn_finalize_purchases}</div>
                </div>
              </div>
            </div>

            <div className="promo-line">
              <p className="promo-line-text">{_lang_cart.text_discont_message}</p>
              <div className="promo-line-actions">
                <div className="form-input small">
                  <input 
                    type="text" 
                    id="promo-code" 
                    name="promo_code" 
                    value={coupon_code}
                    onChange={(e) => set_coupon_code(e.target.value)}
                  />
                </div>
                <p onClick={add_coupon} className="button secondary">{_lang_cart.btn_discont}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cart;
