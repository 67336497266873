const  _lang_chat = {
  title: "SUPORTE",
  sub_title: "SUPORTE",
  btn_title: "INICIAR CHAT",
  inp_title: "Digite sua pergunta",
  
  bot_messages: {
    chat_finalizado: "Chat finalizado",
    aguarde_um_instante: "Aguarde um instante já vamos te atender...",
    seja_bem_vindo_de_volta: "Seja bem vindo de volta",
    tempo_medio: "Tempo médio de resposta e de 5 minutos caso demore mais que isso entre em contato no nosso discord",
    msg_digitando: "Digitando"
  },
  messages: {
    chat_finalizado: "Chat finalizado com sucesso",
    nenhum_atendende: "Nenhum atendente agora para te atender...",
    precisa_estar_logado: "Você precisa estar logado",
    sincronizado: "Conta sincronizada com sucesso"
  }
}

module.exports =  { _lang_chat };
