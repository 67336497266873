const _lang_profile_options = {
  payment_ids: {
    "0": "Invalid",
    "1": "PIX",
    "2": "PIX",
    "3": "STRIPE",
    "4": "MERCADO PAGO",
    "5": "CREDIT CARD",
  },
  payment_status: {
    "completed": "Completed",
    "pending":   "Pending",
    "cancelled": "Cancelled",
    "on-hold":   "On Hold",
    "failed":    "Failed",
  },

  timer_expired: "The payment time has expired",
  order_cancelled: "This order is cancelled",
  order_completed: "Payment completed",
}

module.exports = { _lang_profile_options };
