const _lang_home = {
  slider__area: {
    header_text: `MAXIMIZE SUAS CHANCES DE VITÓRIA COM OS NOSSOS MODS.`,
    header_btn: "SABER MAIS",
  },
  about__area: {
    about__funFact: "NUNCA FOI SORTE SEMPRE FOI",
    title: "AGORA VOCÊ TAMBÉM PODE SER UM PRO PLAYER",
    about__content: `Se você é um jogador ávido, sabe o quão importante é ter todas as ferramentas necessárias para maximizar sua experiência de jogo. O nosso sistema de mods em jogos é a solução perfeita para jogadores que procuram melhorar sua jogabilidade e obter uma vantagem competitiva.`,
    textPath: "qualidade garantida",
    info_serve: {
      members: "MEMBROS",
      mods: "MODS",
      years: "ANOS"
    },
    about_btn: {
      about_us: "SOBRE NÓS"
    },
  },
  streamers__area: {
    header_text: "TODOS OS NOSSOS MODS",
    animate_text: "Atualizações diárias",
  },
  project_area: {
    header_text: "TODAS AS NOVIDADES DA PROJECT",
    animate_text: "fique por dentro de tudo",
  },
  social_area: {
    header_text: "fique sempre conectado",
    animate_text: "conecte-se com a project",
  }
}

module.exports = { _lang_home };
