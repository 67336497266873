const SocialLinks = {
  link_facebook:        "https://www.facebook.com/projectoficialmods",
  link_twiter:          "https://twitter.com/projectoficial_",
  link_instagram:       "https://www.instagram.com/projectoficialmods",
  link_youtube:         "https://www.youtube.com/@ProjectOficial",
  link_discord:         "https://discord.gg/S4PM5vcjMW",
  link_tiktok:          "https://www.tiktok.com/@projectoficial",
  link_download_loader: "https://projectdow.com/data/Setup.exe",
  link_live_tiktok:     "https://www.tiktok.com/@projectoficialmods/live",
  link_whatsapp:        "https://wa.me/553193278197",
}

export default SocialLinks;
