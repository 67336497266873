import { AUTH_STORE }                                 from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const validate_email = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const validate_name = (name) => {
  const has_valid_length            = name.length > 1;
  const has_only_allowed_characters = /^[\p{L}\p{N}\s]*$/u.test(name); // permite letras, números e espaços
  return has_valid_length && has_only_allowed_characters;
};

const validate_cpf = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  const calcCheckDigit = (cpf, factor) => {
    let total = 0;
    for (let i = 0; i < factor - 1; i++) {
      total += cpf[i] * (factor - i);
    }
    const remainder = (total * 10) % 11;
    return remainder === 10 ? 0 : remainder;
  };
  const firstCheckDigit = calcCheckDigit(cpf, 10);
  const secondCheckDigit = calcCheckDigit(cpf, 11);
  return firstCheckDigit === parseInt(cpf[9]) && secondCheckDigit === parseInt(cpf[10]);
};

const validate_card_number = (number) => {
  const regex = /^[0-9]{16}$/;
  return regex.test(number.replace(/\s+/g, ''));
};

const validate_expiry_date = (date) => {
  const regex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
  if (!regex.test(date)) return false;
  
  const [month, year] = date.split('/');
  const expiryDate = new Date(`20${year}`, month);
  return expiryDate > new Date();
};

const validate_cvv = (cvv) => {
  const regex = /^[0-9]{3,4}$/;
  return regex.test(cvv);
};

const CHECKOUT_AUTH = async (current_user) => {
  const products     = current_user.products_cart.map((item) => ({quantity: item.product_amount, product_id: item.product_id}));
  let payment_credit = {};

  if(!validate_email(current_user.payment_data.payment_email)) 
    return { status_code_message: _lang_base.requests["603"], success: false };
  else if(!validate_name(current_user.payment_data.payment_first_name)) 
    return { status_code_message: _lang_base.requests["613"], success: false };
  else if(!validate_name(current_user.payment_data.payment_last_name))
    return { status_code_message: _lang_base.requests["614"], success: false };
  else if(!validate_cpf(current_user.payment_data.payment_cpf) && current_user.payment_currency === "BRL")
    return { status_code_message: _lang_base.requests["615"], success: false };

  if(current_user.payment_method === "5" && !validate_card_number(current_user.payment_credit.payment_credit_number))
    return { status_code_message: _lang_base.requests["915"], success: false };
  else if(current_user.payment_method === "5" && !validate_expiry_date(current_user.payment_credit.payment_credit_valid))
    return { status_code_message: _lang_base.requests["916"], success: false };
  else if(current_user.payment_method === "5" && !validate_name(current_user.payment_credit.payment_credit_name))
    return { status_code_message: _lang_base.requests["917"], success: false };
  else if(current_user.payment_method === "5" && !validate_cvv(current_user.payment_credit.payment_credit_code))
    return { status_code_message: _lang_base.requests["918"], success: false };

  if(current_user.payment_method === "5") {
    const [asaas_cc_expiration_month, asaas_cc_expiration_year] = current_user.payment_credit.payment_credit_valid.split('/');
    payment_credit                                              = {
      asaas_cc_number: current_user.payment_credit.payment_credit_number.replace(/\s+/g, ''),
      asaas_cc_expiration_month,
      asaas_cc_expiration_year,
      asaas_cc_name: current_user.payment_credit.payment_credit_name,
      asaas_cc_security_code: current_user.payment_credit.payment_credit_code,
    }
  }

  try {
    const data = new URLSearchParams();
    data.append('token',               current_user.user.user_token_product);
    data.append('products',            JSON.stringify(products));
    data.append('payment_currency',    current_user.payment_currency);
    data.append('payment_method',      current_user.payment_method);
    data.append('payment_email',       current_user.payment_data.payment_email);
    data.append('payment_first_name',  current_user.payment_data.payment_first_name);
    data.append('payment_last_name',   current_user.payment_data.payment_last_name);
    data.append('payment_cpf',         current_user.payment_data.payment_cpf.replace(/[.\\-]/g, ''));
    data.append('payment_credit',      JSON.stringify(payment_credit));
    data.append('coupon_code',         (current_user.coupon_code !== undefined) ? current_user.coupon_code : "");
    
    data.append('g-recaptcha-response', current_user.rpf);
    const _checkout_auth = await AUTH_STORE.post('/routes/checkout.php', data).then((response) => response.data);
    _checkout_auth.status_code_message = _lang_base.requests[_checkout_auth.status_code];
    return _checkout_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default CHECKOUT_AUTH;
