const _lang_recharge = {
  like_video: "LIKE VIDEO",
  follow_channel: "FOLLOW CHANNEL",
  btn_cancel: "CANCEL",
  btn_next: "NEXT",
  sub_title: "Winning is no longer a matter of luck. With our system, you can win consistently.",
  btn_recharge: "RECHARGE NOW",

  text_verify_waiting: "Waiting...",
  text_verify_follown: "Checking if you followed",
  text_verify_enjoy: "Checking if you liked it",
  
  msg_verify_success: "Verification successful",
  msg_error_adblock: "Adblock is enabled, please disable it",
};

module.exports = { _lang_recharge };
