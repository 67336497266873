import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const RECHARGE_AUTH = async (current_user) => {
  try {
    const current_time  = Math.floor(Date.now() / 1000);
    if(current_user.product_free.length > 0 && current_user.product_free[0].access_recharge > current_time) new Error();
    const data = new URLSearchParams();
    data.append('token',  current_user.user.user_token_product);
    data.append('g-recaptcha-response', current_user.rpf);
    const _recharge_auth = await AUTH.post('/routes/recharge.php', data).then((response) => response.data);
    _recharge_auth.status_code_message = _lang_base.requests[_recharge_auth.status_code]
    return _recharge_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default RECHARGE_AUTH;
