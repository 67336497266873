
import { useState, useContext, useEffect }            from "react";
import { useNavigate }                                from "react-router-dom";
import { useSnackbar }                                from 'notistack';
import Backdrop                                       from '@mui/material/Backdrop';
import CircularProgress                               from '@mui/material/CircularProgress';

import LoginEmbed                                     from "../../Components/LoginEmbed/LoginEmbed";
import account_hub_icon                               from "../../assets/img/icons/profile/accounthub-icon.png"
import CreateSvg                                      from "../../Components/Common/CreateSvg";

import { gen_coin_type, fomate_date }                 from "../../Utils/StoreFunction";
import GETALLORDERS_AUTH                              from "../../Utils/AUTH/GETALLORDERS_AUTH";
import Context                                        from "../../Provider/Context";
import { _lang_profile_options, _lang_base }          from "../../Languages/languages";

function ProfileOptions() {
  const history                                       = useNavigate();
  const [backdrop_open, set_backdrop_open]            = useState(false);
  const { enqueueSnackbar }                           = useSnackbar();

  const [orders_properties, set_orders_properties]    = useState([])
  const { UserInfoBase, UserCheckout }                = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  useEffect(() => {
    (async () => {
      try {
        set_backdrop_open(true);
        const RESULT_GET_ALL_ORDERS = await GETALLORDERS_AUTH(UserInfo.user);
        set_backdrop_open(false);
        if(RESULT_GET_ALL_ORDERS.success) {
          enqueueSnackbar(RESULT_GET_ALL_ORDERS.status_code_message, {variant: 'success'});
  
          delete RESULT_GET_ALL_ORDERS.status_code_message;
          delete RESULT_GET_ALL_ORDERS.success;
          delete RESULT_GET_ALL_ORDERS.status_code;
  
          set_orders_properties(RESULT_GET_ALL_ORDERS.orders);
          return;
        }
        enqueueSnackbar(RESULT_GET_ALL_ORDERS.status_code_message, {variant: 'error'});
      } catch (error) {
        set_backdrop_open(false);
        enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!UserInfo || UserInfo.user === undefined) return <LoginEmbed /> ;
  
  const payment_the_order = (order) => {
    console.log(order);
    const current_time    = Math.floor(Date.now() / 1000);
    if(order.order_status === "cancelled")          return enqueueSnackbar(_lang_profile_options.order_cancelled, {variant: 'error'});
    else if(order.order_status === "completed")     return enqueueSnackbar(_lang_profile_options.order_completed, {variant: 'success'});
    else if(current_time > order.order_time_expire) return enqueueSnackbar(_lang_profile_options.timer_expired,   {variant: 'error'});
    UserCheckout.Update(order);
    history(`/checkout/${order.order_id}`);
  }

  const component_table_orders = (order, index) =>
    <div className="table-row micro" key={`order-${index}`}>
      <div className="table-column">
        <p className="table-text"><span className="light">{fomate_date(order.date_created.date)}</span></p>
      </div>
      <div className="table-column padded-left">
        <div onClick={() => payment_the_order(order)} className="table-link table-link-two"><span className="highlighted">#{order.order_id}</span></div>
      </div>
      <div className="table-column centered padded">
        <p className="table-title">{_lang_profile_options.payment_status[order.order_status]}</p>
      </div>
      <div className="table-column centered padded">
        <p className="table-title">{gen_coin_type(order.order_total, (
          order.order_currency !== undefined && 
          order.order_currency !== null      && 
          order.order_currency !== "") ? order.order_currency : "BRL")}</p>
      </div>
      <div className="table-column centered padded">
        <p className="table-title">{_lang_profile_options.payment_ids[(
          order.order_method !== undefined && 
          order.order_method !== null      && 
          order.order_method !== "") ? order.order_method : 0]}</p>
      </div>
      <div className="table-column padded-left">
        <div className="percentage-diff-icon-wrap positive" onClick={() => payment_the_order(order)} >
          <CreateSvg svgname="svg_plus_small" svgclassname="percentage-diff-icon icon-plus-small" />
        </div>
      </div>
    </div>

  return(
    <>
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container profile">
        <div className="content-grid">
          <div className="section-banner">
            <img className="section-banner-icon" src={account_hub_icon} alt={_lang_base.web_site_name} />
            <p className="section-banner-title">Central de contas</p>
            <p className="section-banner-text">Informações de perfil, mensagens, configurações e muito mais!</p>
          </div>

          <div className="grid grid-3-9">
            <div className="account-hub-sidebar">
              <div className="sidebar-box no-padding">
                <div className="sidebar-menu round-borders">

                  <div className="sidebar-menu-item">
                    <div className="sidebar-menu-header accordion-trigger-linked">
                      <CreateSvg svgname="svg_store" svgclassname="sidebar-menu-header-icon icon-store" />
                      <div className="sidebar-menu-header-control-icon">
                        {/* <CreateSvg svgname="svg_minus_small" svgclassname="sidebar-menu-header-control-icon-open icon-minus-small" /> */}
                        <CreateSvg svgname="svg_plus_small" svgclassname="sidebar-menu-header-control-icon-closed icon-plus-small" />
                      </div>
                      <p className="sidebar-menu-header-title">Loja</p>
                      <p className="sidebar-menu-header-text">Revise sua conta, gerencie estatísticas de verificação de produtos e muito mais!</p>
                    </div>
                    <div style={{overflow: "hidden", height: "161px", transition: "all 0.3s ease-in-out 0s"}}>
                      <div className="sidebar-menu-body accordion-content-linked accordion-open">
                        <div className="sidebar-menu-link sidebar-two active">Meus Pedidos</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="account-hub-content">
              <div className="section-header">
                <div className="section-header-info">
                  <p className="section-pretitle">Loja</p>
                  <h2 className="section-title">Meus Pedidos</h2>
                </div>
              </div>
              
              <div className="table-wrap" data-simplebar>
                <div className="table table-sales">
                  <div className="table-header">
                    <div className="table-header-column">
                      <p className="table-header-title">Date</p>
                    </div>
                    <div className="table-header-column padded-left">
                      <p className="table-header-title">Pedido</p>
                    </div>
                    <div className="table-header-column centered padded">
                      <p className="table-header-title">Status</p>
                    </div>
                    <div className="table-header-column centered padded">
                      <p className="table-header-title">TOTAL</p>
                    </div>
                    <div className="table-header-column centered padded">
                      <p className="table-header-title">Método Pagamento</p>
                    </div>
                    <div className="table-header-column padded-left"></div>
                  </div>

                  <div className="table-body same-color-rows">
                    {orders_properties.map((item, index) => component_table_orders(item, index))}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileOptions;
