const _lang_lost_password = {
  tile_password: "Forgot your password?",
  sub_title: "Ok, don't panic. You can recover it here",
  btn_email: "Send Request",

  sub_title_two: "Now type the password you want twice",
  btn_change_password: "Change Password",

  place_user: "Email",
  place_password: "Enter your new password",
  place_sub_password: "Re-enter your new password",
}

module.exports = { _lang_lost_password };
