import { useState, useEffect, useContext }           from 'react';
import { useNavigate  }                              from "react-router-dom";
import Backdrop                                      from '@mui/material/Backdrop';
import CircularProgress                              from '@mui/material/CircularProgress';

import { useSnackbar }                               from 'notistack';
import Zoom                                          from 'react-medium-image-zoom';

import { SOCKET_CHAT }                               from '../../Socket/Conexao';
import Context                                       from "../../Provider/Context";
import { _lang_chat, _lang_base }                    from "../../Languages/languages";

import { _save_storage, storage_vars, 
  _remove_storage, _get_storage }                    from "../../Storage/Storage";
import { useSynchronizeAccount }                     from "../../Hooks/SynconizeAccount";
import { EXIT_ACCOUNT }                              from '../../Utils/UserFunction';

// IMPORT IMAGENS
import loadingIcon                                   from "../../assets/img/icons/loading.gif";
import chatIcon                                      from "../../assets/img/icons/chat.png";
import enviarIcon                                    from "../../assets/img/icons/enviar.png";

const Chat = () => {
  const history                                 = useNavigate();
  const { SocialLinks, Chat }                   = useContext(Context);
  const [openChat, setOpenChat]                 = useState(false);
  const [containerIcon, setcontainerIcon]       = useState(true);
  const [carregando, setCarregando]             = useState(false);
  const [digitando, setDigitando]               = useState(false);
  const [containerChat, setContainerChat]       = useState(true);
  const [userName, setuserName]                 = useState("");
  const [textos, setTextos]                     = useState([]);
  const { enqueueSnackbar }                     = useSnackbar();
  const [inputText, setInputText]               = useState("");
  const [notiIcon, setnotiIcon]                 = useState(false);
  const [notQuant, setNotQuant]                 = useState(0);
  const [chatFinal, setchatFinal]               = useState(true);
  const { synchronizeAccount, backdrop_open }   = useSynchronizeAccount();

  const [cDigitando, setCDigitando]             = useState(0);;
  const [typingText, setTypingText]             = useState(_lang_chat.bot_messages.msg_digitando);
  const dots                                    = ['.', '..', '...', '....'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCDigitando((cDigitando < dots.length - 1) ? cDigitando + 1 : 0);
      setTypingText(`${_lang_chat.bot_messages.msg_digitando}${dots[cDigitando]}`);
    }, 500);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cDigitando]);

  useEffect(() => {
    const verificador_mensagens = (menssagem) => {
      if(menssagem.includes("www.youtube.com/watch") || menssagem.includes("https://www.youtube.com/embed/")) {
        menssagem = (<div dangerouslySetInnerHTML={ { __html: `<iframe 
          width="100%" height="100%" 
          src="https://www.youtube.com/embed/${menssagem.match(/v=([^&]+)/)[1]}" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; 
          clipboard-write; 
          encrypted-media; gyroscope; 
          picture-in-picture; web-share" allowfullscreen></iframe>`} } 
        />);
      } else if(menssagem.includes("cdn.discordapp.com/attachments/") || menssagem.includes("i.imgur.com/") || menssagem.includes("media.discordapp.net/attachments/")) {
        menssagem = (<Zoom><div dangerouslySetInnerHTML={ { __html: `<img width="100%" height="100%" src=${menssagem.match(/(https?:\/\/[^\s]+)/)[0]} alt="${_lang_base.web_site_name}" />`} } /></Zoom>);
      }
      return menssagem;
    }

    SOCKET_CHAT.on('mensagem_admin', (req) => {
      if (openChat === false) new Audio('/assets/audio/notificacao.mp3').play();

      setNotQuant(notQuant + 1);
      setnotiIcon(true);

      setTextos([...textos, {
        valor: verificador_mensagens(req.message),
        nome: "PROJECT BOT",
        classe: "self"
      }]);
      setDigitando(false);
    });

    SOCKET_CHAT.on('mensagem_end', () => {
      setTextos([...textos, {
        valor: _lang_chat.bot_messages.chat_finalizado,
        nome: "PROJECT BOT",
        classe: "self"
      }]);

      _remove_storage(storage_vars.chat_auth);
      setOpenChat(false);
      setcontainerIcon(true);
      setCarregando(false);
      setchatFinal(false);
      enqueueSnackbar(_lang_chat.messages.chat_finalizado, {variant: 'success'});
    });

    SOCKET_CHAT.on('digitando', (req) => {
      setDigitando(req.digitando);
      setTimeout(() => {
        const element = document.getElementById("menssages-container");
        if(element !== null) element.scrollBy({ top: 10000, behavior: 'smooth'});   
      }, 300);
    });

    SOCKET_CHAT.on('sincronizar_conta', async () => {
      await synchronizeAccount();
    });

    SOCKET_CHAT.on('deslogar_usuario', () => {
      EXIT_ACCOUNT();
    });

    const element = document.getElementById("menssages-container");
    if(element !== null) element.scrollBy({ top: 10000 });   

    return () => {
      SOCKET_CHAT.off('mensagem_admin');
      SOCKET_CHAT.off('mensagem_end');
      SOCKET_CHAT.off('sincronizar_conta');
      SOCKET_CHAT.off('deslogar_usuario');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, notQuant, openChat, textos]);

  useEffect(() => {
    const element = document.getElementById("menssages-container");
    if(element !== null) element.scrollBy({ top: 10000 });   
  }, [inputText]);

  useEffect(() => {
    if(Chat.Value) icone_click();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Chat.Value])
  
  const socket_start_chat = (usuario) => {
    SOCKET_CHAT.connect();

    setInterval(() => {
      if(!SOCKET_CHAT.connected) {
        enqueueSnackbar(_lang_chat.messages.nenhum_atendende, {variant: 'success'});
        window.open(SocialLinks.link_discord, '_blank');
        window.location.reload();
        return;
      }
    }, 10000);

    SOCKET_CHAT.on('connect', () => {
      const verificar_chat = _get_storage(storage_vars.chat_auth);
      if(verificar_chat === null) {
        usuario.user.linguagemNavegador = navigator.language || navigator.userLanguage;
        usuario.user.linguagemNavegador = (usuario.user.linguagemNavegador === undefined || usuario.user.linguagemNavegador === "pt-BR") ? "Português" : "English";
        SOCKET_CHAT.emit('enter-chat', { ...usuario.user, create: true });
      } else {
        SOCKET_CHAT.emit('enter-chat', { ...verificar_chat, create: false });
      }
      SOCKET_CHAT.on('chat-online', (user) => {
        let menssagem_boas_vindas = {};

        if(user.new_user) {
          menssagem_boas_vindas = {
            valor: _lang_chat.bot_messages.aguarde_um_instante,
            nome: "PROJECT BOT",
            classe: "self"
          }
        } else {
          menssagem_boas_vindas = {
            valor: _lang_chat.bot_messages.seja_bem_vindo_de_volta,
            nome: "PROJECT BOT",
            classe: "self"
          }
        }
        _save_storage(storage_vars.chat_auth, user);
        setTextos([...textos, menssagem_boas_vindas, {
          valor: _lang_chat.bot_messages.tempo_medio,
          nome: "PROJECT BOT",
          classe: "self"
        }]);
        setTimeout(() => { setCarregando(false); setcontainerIcon(false); }, 1000);
      });
    });
  }

  const icone_click = () => {
    Chat.Update(false);
    setnotiIcon(false);
    setNotQuant(0);

    setOpenChat(true);
    setCarregando(true);
    
    setTimeout(() => {
      setCarregando(false);
      setcontainerIcon(false);
    }, 1000);

    setTimeout(() => {
      const element = document.getElementById("menssages-container");
      if(element !== null) element.scrollBy({ top: 10000, behavior: 'smooth'});   
    }, 1300);

  }

  const start_chat = async () => {
    const user_variavel = _get_storage(storage_vars.user_auth);
    if(user_variavel === null || user_variavel === "") {
      enqueueSnackbar(_lang_chat.messages.precisa_estar_logado, { variant: 'error' });
      history('/login');
      return;
    }
    setuserName(user_variavel.user.user_login);
    socket_start_chat(user_variavel);
    setOpenChat(true);
    setCarregando(true);
    setContainerChat(false);
  }

  const fechar_chat = () => {
    setnotiIcon(false);
    setNotQuant(0);
    setOpenChat(false);
    setcontainerIcon(true);
    setCarregando(false);
    //setContainerChat(true);
  }

  const enviar_mensagem = () => {
    if(inputText.length <= 0) return;
    SOCKET_CHAT.emit('message-send', inputText);
    setInputText("");
    setTextos([...textos, {
      valor: inputText,
      nome: userName,
      classe: "other"
    }]);
  }

  return(
  <>
    <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
      <CircularProgress color="inherit" />
    </Backdrop>
    <div className={(openChat) ? "floating-chat enter expand" : "floating-chat enter"}>
      {(openChat) &&
      <div className="header-discord">
          <span className="title-discord">{_lang_chat.title}</span>
          <button onClick={fechar_chat} id="close-chat"><i>-</i></button>          
        </div>
      }
      {(carregando) ? 
        <div className="chat-discord" style={{ opacity: (carregando) ? 1 : 0}}>
          <div className="carregando-chat">
            <div>
              <img src={loadingIcon} alt="CARREGANDO" />
            </div>
          </div>
        </div>
        :
        (containerIcon) ? 
          <div onClick={icone_click} id="open-chat">
            <i id="icon-chat"></i>
            <img id="img-chat-ballon" src={chatIcon} alt={_lang_base.web_site_name} />
            {(notiIcon) && <div id="notificacao-info">{notQuant}</div>}
          </div>
        :
        <div className="chat-discord enter">
          {(containerChat) ? 
            <>
              <div className="container-cadastro-chat">
                <div className="login-cadastro-chat">
                  <h1>{_lang_chat.sub_title}</h1>
                    <button onClick={start_chat}  id="btn-cadastro-chat" className="btn btn-primary btn-block btn-large">{_lang_chat.btn_title}</button>
                </div>
              </div>
            </>
            : 
            <>
              <ul id="menssages-container" className="messages">{textos.map((item, index) => 
                <li className={item.classe} key={index}>
                  <p className="title-message">{item.nome}</p>
                  <p className="chat-date">{item.valor}</p>
                </li>)}
                {(digitando) && 
                <li className="self">
                  <p className="title-message">PROJECT BOT</p>
                  <p className="chat-date">{typingText}</p>
                </li>
                }
              </ul>
              <div className="footer">
                {(chatFinal) &&
                <input 
                  onKeyDown={(event) => (event.key === "Enter") && enviar_mensagem()} 
                  onChange={(e) => setInputText(e.target.value)} 
                  value={inputText} 
                  placeholder={_lang_chat.inp_title} 
                  autoComplete="off" 
                  className="text-box" 
                  id="one-message" 
                />}
                {(chatFinal) && <img onClick={enviar_mensagem} src={enviarIcon} alt={_lang_base.web_site_name} />}
              </div>
            </>
          }
        </div>
      }
    </div>
  </>
  )
}

export default Chat;
