const _lang_store = {
  category_list: {
    title_date: "Data de publicação",
    title_price: "Preço",
  },
  order_list: {
    title_descendente: "Descendente",
    title_ascendente: "Ascendente"
  },
  section_title: "Loja",
  section_pretitle: "PROCURAR PRODUTOS",
  section_info_title: "Itens Digitais",
  section_subsection_title:"Loja",
  section_subsection_pretitle: "Itens Digitais",
  input_items_search: "Itens de pesquisa",
  label_filter_category: "Filtrar por",
  label_filter_order: "Ordenar por",
  siderbar_title: "Categorias",
  check_box_category_all: "Todas as Categorias",
  btn_aply_filter_by_category: "Aplicar filtro de categoria",
}

module.exports = { _lang_store };
