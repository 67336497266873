/* IMPORT LANGUAGE */
import { _lang_404 }                             from "../../Languages/languages";

function ComponentNotFound() {
  return(
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="product_not_found_container">
              <div title="404" className="product_not_found">404</div>
              <div className=""><span>{_lang_404.title}</span></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ComponentNotFound;
