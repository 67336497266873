const _lang_terms_privacy = {
  title_header: "Privacy Terms",
  breadcrumb_item: "Home",
  sub_title: "READ THIS LICENSE CAREFULLY BEFORE USING THIS SOFTWARE.",
  title: "END USER LICENSE AGREEMENT",
  informacacoes: [
    {
      titulo: "",
      texto: `By receiving, opening the files, and/or using the Official Project, you agree that this End User License Agreement (EULA) is a valid contract and agree to be bound by it. You agree to abide by intellectual property laws and all terms and conditions of this Agreement.
      Subject to the terms of this Agreement, the Official Project grants a limited, non-exclusive, non-transferable license, without the right to sublicense, to use the Official Project in accordance with this Agreement and any other written agreement with the Official Project. The Official Project does not transfer the title of the Official Project to you. This agreement is a binding legal agreement between the Official Project and the purchasers or users of the Official Project.
      If you do not agree to be bound by this agreement, remove the Official Project from your computer.`
    },
    {
      titulo: "DISTRIBUTION",
      texto: `The Official Project, the license, the account created here, and everything acquired in it will not be copied, shared, distributed, resold, offered for resale, transferred, or sublicensed, in whole or in part. For information on redistributing the Official Project, contact the Official Project team.`
    },
    {
      titulo: "USER AGREEMENT",
      texto: `The license to use the Official Project is limited to the number of licenses purchased by you. You must not allow others to use your account.
      Any failure to comply with the terms and conditions of this Agreement will result in the automatic and immediate cancellation of this license. Upon termination of this license granted herein for any reason, if you agree to immediately cease using the Official Project.`
    },
    {
      titulo: "WARRANTY DISCLAIMER",
      texto: `This software and accompanying files are sold 'as is' and without warranties of performance or merchantability or any other warranty, express or implied. This disclaimer fills all files generated and edited by the Official Project.`
    },
    {
      titulo: "RELATED TO EVIDENCE",
      texto: `This software and accompanying files are sold 'as is' and without warranties of performance or merchantability or any other warranty, express or implied. This disclaimer fills all files generated and edited by the Official Project.
      Official Project does not sign any responsibility with accounts as a result of the use of this site or any software or data contained in it. The user is solely responsible for their online gaming activities, and any loss of any of their accounts,
      financial or otherwise, is your responsibility. Although we make every effort to ensure that you remain undetected by any anti-mod software, we cannot always guarantee it. Any 'game bans',
      occurring during the duration of your license are your sole responsibility, if you agree that the Official Project cannot be held liable for such an event and that such occurrence does not guarantee the refund of the Official Project since the product is still functional.
      `
    },
    {
      titulo: "REFUNDS",
      texto: `The Official Project will only refund the product if you follow the Refund Policy within 2 hours of purchase.`
    },
    {
      titulo: "RELATED TO PROCEDURE",
      texto: `For any questions, send an email to suporte@projectoficial.com.`
    }
  ],
}

module.exports = { _lang_terms_privacy };
