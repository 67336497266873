const _lang_header_top_layout       = {
  header_btn: {
    home: "HOME",
    loja: "LOJA",
    mods: "MODS",
    discord: "DISCORD",
    faq: "F.A.Q",
    user_is_not_logged_in: "VOCÊ NÃO ESTÁ LOGADO",
    entrar: "ENTRAR",
    atualizar_conta: "SINCRONIZAR CONTA",
    vincular_discord: "VINCULAR DISCORD",
    sair: "SAIR"
  },
  offCanvas__wrap: {
    title: "AGORA VOCÊ TAMBÉM PODE SER UM",
    title_dois: " PRO PLAYER"
  },
  timer: {
    recharge: "RECARREGAR",
    can_recharge: "LIBERADO PARA RECARREGAR",
    timer_text:   "TEMPO",
    day:     "DIA",
    days:    "DIAS",
    hour:    "HORA",
    hours:   "HORAS",
    minute:  "MINUTO",
    minutes: "MINUTOS",
    time_is_over: "TEMPO ESGOTADO"
  },
}

module.exports = { _lang_header_top_layout };
