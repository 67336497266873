import { useState, useContext }                       from "react";

import Hexagon                                        from "react-hexagon"
import LoginEmbed                                     from "../../Components/LoginEmbed/LoginEmbed";
import Context                                        from "../../Provider/Context";

import CircularProgress                               from '@mui/material/CircularProgress';
import Typography                                     from '@mui/material/Typography';
import Box                                            from '@mui/material/Box';

/* IMPORT IMAGENS */
import base_icon                                      from "../../assets/img/badge/base_icon.png";
import base_backgoround                               from "../../assets/img/badge/base.png";
import completed_quests                               from "../../assets/img/badge/completedq-s.png";
import unlocked_badge                                 from "../../assets/img/badge/unlocked-badge.png";
import like_icon                                      from "../../assets/img/icons/profile/like.png";
import { _lang_perfil, _lang_base }                   from "../../Languages/languages";

function Profile() {
  const [progress, setProgress]                       = useState(0);

  const { UserInfoBase }                              = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  if (!UserInfo || UserInfo.user === undefined) return <LoginEmbed />;

  const user_infos                                    = (UserInfo.user_info) ? {
    user_login:           UserInfo.user.user_login.substring(0, 18),
    user_phrase:          UserInfo.user_info.user_phrase.substring(0, 25),
    user_level:           UserInfo.user_info.user_level,
    user_avatar:          UserInfo.user_info.user_avatar,
    user_background:      UserInfo.user_info.user_background,
    user_posts:           UserInfo.user_info.user_posts,
    user_folowers:        UserInfo.user_info.user_folowers,
    user_visits:          UserInfo.user_info.user_visits,
    user_language:        "brl",
    user_badges:          [],
    user_bages_favorites: [],
    user_activities:      [],
  } : {
    user_login:           UserInfo.user.user_login.substring(0, 18),
    user_phrase:          "",
    user_level:           "0",
    user_avatar:          "/assets/img/avatar/perfil.jpg",
    user_background:      "/assets/img/avatar/background.jpg",
    user_posts:           "0",
    user_folowers:        "0",
    user_visits:          "0",
    user_language:        "eng",
    user_badges:          [],
    user_bages_favorites: [],
    user_activities:      [],
  };

  const component_activities = user_infos.user_activities.map((item, index) => 
    <div key={index} className="user-status">
      <div className="user-status-activity activity-reaction">
        <svg className="user-status-activity-icon icon-thumbs-up" id="svg-thumbs-up" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
          <path d="M15.896,19.999c-0.01,0-0.021,0-0.03,0H2.826C1.268,19.999,0,18.743,0,17.201v-6.302c0-1.543,1.269-2.797,2.826-2.797h2.096l3.391-7.515C8.473,0.229,8.83,0,9.223,0c2.063,0,3.742,1.659,3.742,3.698l0.026,2.3h4.996c0.152-0.003-0.149-0.023,0,0c0.753,0.111,1.962,0.245,1.999,1c0.037,0.757-0.011,2.143,0,3.003l-1.295,7.624C18.483,18.982,17.284,19.999,15.896,19.999z M6.995,18.005h8.881c0.003,0,0.007,0,0.01,0c0.418,0,0.77-0.292,0.83-0.684l1.262-8.106c0.031-0.205-0.02-0.412-0.145-0.583c-0.132-0.178-0.621-0.601-0.845-0.634c-0.045-0.008,0.045-0.006,0,0h-4.996c-0.553,0-1-1.451-1-2.001l-0.026-2.3c0-0.733-0.477-1.36-1.144-1.6l-2.828,6.9V18.005z M2.997,10c-0.456,0-0.999,0.457-0.999,0.899v6.302c0,0.442,0.371,0.804,0.827,0.804h2.17V10H2.997z"/>
        </svg>
      </div>
      <p className="user-status-title">
        <a className="bold" href="profile-timeline.html">Marina Valentine</a> left a love 
        <img className="reaction" src={like_icon} alt={_lang_base.web_site_name} /> reaction on 
        <a className="highlighted" href="profile-timeline.html">Destroy Dex</a>'s 
        <a className="highlighted" href="profile-timeline.html">status update</a></p>
      <p className="user-status-timestamp small-space">3 minutes ago</p>
    </div>
  );

  const component_badges = user_infos.user_badges.map((item, index) => 
    <div key={index} className="badge-item text-tooltip-tft">
      <img src={"assets/img/badge/icons/1.png"} alt={_lang_base.web_site_name} />
    </div>
  );

  const component_badges_header = user_infos.user_bages_favorites.map((item, index) => 
    <div key={index} className="social-link">
      <img src={"assets/img/badge/icons/1.png"} alt={_lang_base.web_site_name} />
    </div>
  );

  return(
    <>
      <div className="container profile">
        <div className="content-grid">
          <div className="profile-header">
            <figure 
              className="profile-header-cover liquid"
              style={{background: `url('${user_infos.user_background}') center center / cover no-repeat`}}
            ></figure>
            <div className="profile-header-info">

            <div className="user-short-description big" bis_skin_checked="1">
              <div className="user-short-description-avatar user-avatar big">
                <div className="user-avatar-border" bis_skin_checked="1">
                  <div className="hexagon-148-164" bis_skin_checked="1" style={{ width: '148px', height: '164px', position: 'relative' }}>
                    <Hexagon width="148" height="164" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround} />
                  </div>
                </div>
                <div className="user-avatar-progress" bis_skin_checked="1">
                  <div className="hexagon-progress-124-136" bis_skin_checked="1" style={{ width: '124px', height: '136px', position: 'relative' }}>
                    <Hexagon width="124" height="136" style={{ position: 'absolute', top: '0px', left: '0px' }} backgroundImage={user_infos.user_avatar} />
                  </div>
                </div>
                <div className="user-avatar-badge" bis_skin_checked="1">
                  <div className="user-avatar-badge-border" bis_skin_checked="1">
                    <div className="hexagon-40-44" bis_skin_checked="1" style={{ width: '40px', height: '44px', position: 'relative' }}>
                      <Hexagon width="40" height="44" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_icon} />
                    </div>
                  </div>
                  <p className="user-avatar-badge-text">{user_infos.user_level}</p>
                </div>
              </div>
              <p className="user-short-description-title">{user_infos.user_login}</p>
              <p className="user-short-description-text">{user_infos.user_phrase}</p>
            </div>
              <div className="profile-header-social-links-wrap">
                <div id="profile-header-social-links-slider" className="profile-header-social-links">
                  <div className="profile-header-social-link">
                    {component_badges_header}
                  </div>
                </div>
              </div>
              <div className="user-stats">
                <div className="user-stat big">
                  <Typography  className="user-stat-title">{user_infos.user_posts}</Typography>
                  <p className="user-stat-text">{_lang_perfil.user_stat_post}</p>
                </div>
                <div className="user-stat big">
                  <Typography  className="user-stat-title">{user_infos.user_folowers}</Typography>
                  <p className="user-stat-text">{_lang_perfil.user_stat_likes}</p>
                </div>
                <div className="user-stat big">
                  <Typography  className="user-stat-title">{user_infos.user_visits}</Typography>
                  <p className="user-stat-text">{_lang_perfil.user_stat_visits}</p>
                </div>
                <div className="user-stat big">
                  <img className="user-stat-image" src={`assets/img/icons/flag/${user_infos.user_language}.png`} alt={_lang_base.web_site_name} />
                  <p className="user-stat-text">{user_infos.user_language}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid grid-3-6-3">
            <div className="grid-column">
              <div className="widget-box">
                <p className="widget-box-title">{_lang_perfil.widget_box_title_insignia} <span className="highlighted">{user_infos.user_badges.length}</span></p>
                <div className="widget-box-content">
                  <div className="badge-list">
                    {component_badges}
                  </div>
                </div>
              </div>

            </div>
            <div className="grid-column">
              <div className="widget-box">
                <p className="widget-box-title">{_lang_perfil.widget_box_title_activity}</p>
                <div className="widget-box-content">
                  <div className="user-status-list scroll-content">
                    {component_activities}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-column">
              <div className="widget-box">
                <div className="progress-arc-summary">

                  <div className="progress-arc-wrap">
                    <div className="progress-arc">
                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress 
                          color="success"
                          size={150}
                          variant="determinate" 
                          value={progress} 
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="h5" component="div" color="white">{`${Math.round(progress)}%`}</Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>

                  <div className="progress-arc-summary-info">
                    <p className="progress-arc-summary-title">{_lang_perfil.progress_arc_title}</p>
                    <p className="progress-arc-summary-subtitle">{user_infos.user_login}</p>
                    <p className="progress-arc-summary-text">{_lang_perfil.progress_arc_text}</p>
                  </div>
                </div>
                <div className="achievement-status-list">
                  <div className="achievement-status">
                    <p className="achievement-status-progress">0/30</p>
                    <div className="achievement-status-info">
                      <p className="achievement-status-title">{_lang_perfil.achievement_status_title_missions}</p>
                      <p className="achievement-status-text">{_lang_perfil.achievement_status_text_missions}</p>
                    </div>
                    <img className="achievement-status-image" src={completed_quests} alt={_lang_base.web_site_name} />
                  </div>
                  <div className="achievement-status">
                    <p className="achievement-status-progress">0/46</p>
                    <div className="achievement-status-info">
                      <p className="achievement-status-title">{_lang_perfil.achievement_status_title_insignia}</p>
                      <p className="achievement-status-text">{_lang_perfil.achievement_status_text_insignia}</p>
                    </div>
                    <img className="achievement-status-image" src={unlocked_badge} alt={_lang_base.web_site_name} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile;
