import { AUTH }                                         from "../../Api/Conexao";
import { _lang_base }                                   from "../../Languages/languages";
import { verify_user_login, verify_user_email, 
  verify_user_password }                                from "../UserFunction";
  import { verify_token_expired }                       from "../UserFunction";

const REGISTER_AUTH = async (current_user) => {
  try {
    verify_user_login(current_user.user_login);
    verify_user_email(current_user.user_email);
    verify_user_password(current_user.password, current_user.confi_password);
  } catch (error) {
    const status_code = _lang_base.requests[error.message];
    return { status_code_message: status_code, success: false };
  }

  try {
    const data = new URLSearchParams();
    data.append('log',  current_user.user_login);
    data.append('pwd', current_user.password);
    data.append('email', current_user.user_email);
    data.append('g-recaptcha-response', current_user.rpf);
    const _register_auth = await AUTH.post('/routes/register.php', data).then((response) => response.data);
    _register_auth.status_code_message = _lang_base.requests[_register_auth.status_code]
    return _register_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default REGISTER_AUTH;
