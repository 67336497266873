import axios from 'axios';

export const AUTH = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_SYSTEM,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

export const AUTH_STORE = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_STORE,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

