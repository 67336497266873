import Button                                         from '@mui/material/Button';
import Dialog                                         from '@mui/material/Dialog';
import DialogActions                                  from '@mui/material/DialogActions';
import DialogContent                                  from '@mui/material/DialogContent';
import DialogTitle                                    from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme }                 from "@mui/material/styles";

import RefundPolicy                                   from '../../Pages/RefundPolicy/RefundPolicy';
import { _lang_accept_terms }                         from '../../Languages/languages';

const darkTheme                                       = createTheme({ palette: { mode: 'dark' } });

function AcceptTerms(props) {
  const handleClose = () => {
    props.set_accept_terms_open(false);
  };

  const accept_terms_function = () => {
    props.set_accept_terms(true);
    props.set_accept_terms_open(false);
    props.set_accept_terms_error(false);
  }

  return (
    <div className="accept_terms_container">
      <ThemeProvider theme={darkTheme}>        
        <Dialog
          open={props.accept_terms_open}
          onClose={handleClose}
          scroll={"paper"}
          maxWidth="true"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title"></DialogTitle>
          <DialogContent>
            <RefundPolicy />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{_lang_accept_terms.btn_cancel}</Button>
            <Button onClick={accept_terms_function}>{_lang_accept_terms.btn_accept_terms}</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  )
}

export default AcceptTerms;
