import { useEffect, useState, useRef }             from 'react';
import { useParams }                               from "react-router-dom";
import { useSnackbar }                             from 'notistack';

import Backdrop                                    from '@mui/material/Backdrop';
import CircularProgress                            from '@mui/material/CircularProgress';
import ReCAPTCHA                                   from "react-google-recaptcha";
import { LOSTPASSWORD_AUTH_GET_CODE, 
  LOSTPASSWORD_AUTH_RESET_PASSWORD }               from '../../Utils/AUTH/LOSTPASSWORD_AUTH';
  
import { _lang_lost_password, _lang_base }         from "../../Languages/languages";

const LostPassword = () => {
  const { slug }                                          = useParams();
  const recaptchaRef                                      = useRef();
  const { enqueueSnackbar }                               = useSnackbar();
  const [checker_lostpassword, set_checker_lostpassword]  = useState(1);
  const [response_message, set_response_message]          = useState("");
  const [user_email, set_user_email]                      = useState("");
  const [password, set_password]                          = useState("");
  const [confi_password, set_confi_password]              = useState("");
  const [backdrop_open, set_backdrop_open]                = useState(false);

  useEffect(() => {
    if(slug !== undefined && slug.length === 10) set_checker_lostpassword(4);
  }, [slug])

  const get_code_user =  async () => {
    try {
      set_backdrop_open(true);
      const rpf                     = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_ACTIVEKEY_AUTH   = await LOSTPASSWORD_AUTH_GET_CODE({ user_email, rpf });
      recaptchaRef.current.reset();
      set_backdrop_open(false);

      if(RESULT_ACTIVEKEY_AUTH.success) {
        enqueueSnackbar(RESULT_ACTIVEKEY_AUTH.status_code_message, {variant: 'success'});
        set_response_message(RESULT_ACTIVEKEY_AUTH.status_code_message);
        set_checker_lostpassword(2);
        return;
      }
      enqueueSnackbar(RESULT_ACTIVEKEY_AUTH.status_code_message, {variant: 'error'});
      set_response_message(RESULT_ACTIVEKEY_AUTH.status_code_message);
      set_checker_lostpassword(3);
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
      set_response_message(_lang_base.requests.error);
      set_checker_lostpassword(3);
    }
  }

  const reset_password =  async () => {
    try {
      set_backdrop_open(true);
      const rpf                     = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_ACTIVEKEY_AUTH   = await LOSTPASSWORD_AUTH_RESET_PASSWORD({ 
        user_code: slug,
        user_password: password,
        confi_password,
        rpf
      });
      recaptchaRef.current.reset();
      set_backdrop_open(false);

      if(RESULT_ACTIVEKEY_AUTH.success) {
        enqueueSnackbar(RESULT_ACTIVEKEY_AUTH.status_code_message, {variant: 'success'});
        set_response_message(RESULT_ACTIVEKEY_AUTH.status_code_message);
        set_checker_lostpassword(2);
        return;
      }
      enqueueSnackbar(RESULT_ACTIVEKEY_AUTH.status_code_message, {variant: 'error'});
      set_response_message(RESULT_ACTIVEKEY_AUTH.status_code_message);
      set_checker_lostpassword(3);
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
      set_response_message(_lang_base.requests.error);
      set_checker_lostpassword(3);
    }
  }

  const components_response_success =       
    <div className="lostpassword">
      <div className="container-icon">
        <div className="circle-wrapper">
          <div className="success circle"></div>
          <div className="icon">
            <i className="fa fa-check"></i>
          </div>
        </div>
      </div>
      <h2>SUCCESS</h2>
      <p>{response_message}</p>
    </div>

  const components_response_error =       
    <div className="lostpassword">
      <div className="container-icon">
        <div className="circle-wrapper">
          <div className="error circle"></div>
          <div className="icon">
            <i className="fa fa-times"></i>
          </div>
        </div>
      </div>
      <h2>ERROR</h2>
      <p>{response_message}</p>
    </div>

  const components_get_email_user =
    <div className="lostpassword">
      <h2>{_lang_lost_password.tile_password}</h2>
      <p>{_lang_lost_password.sub_title}</p>
      <input onChange={(e) => set_user_email(e.target.value)} value={user_email} type="text" placeholder={_lang_lost_password.place_user} />
      <hr />
      <div className="btn-lost-password">
        <button onClick={get_code_user} >{_lang_lost_password.btn_email}</button>
      </div>
    </div>

  const components_reset_password = 
    <div className="lostpassword">
      <h2>{_lang_lost_password.tile_password}</h2>
      <p>{_lang_lost_password.sub_title_two}</p>
      <input 
        onChange={(e) => set_password(e.target.value)} 
        value={password} type="password" 
        placeholder={_lang_lost_password.place_password} 
      />
      <input 
        onChange={(e) => set_confi_password(e.target.value)} 
        value={confi_password} 
        type="password" 
        placeholder={_lang_lost_password.place_sub_password} 
      />
      <hr />
      <div className="btn-lost-password">
        <button onClick={reset_password} >{_lang_lost_password.btn_change_password}</button>
      </div>
    </div>

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
      />
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="lostpassword-container">
        {(checker_lostpassword === 1) && components_get_email_user}
        {(checker_lostpassword === 2) && components_response_success}
        {(checker_lostpassword === 3) && components_response_error}
        {(checker_lostpassword === 4) && components_reset_password}
      </div>
    </>
  )
}

export default LostPassword;
