const _lang_perfil = {
  user_stat_post: "posts",
  user_stat_likes: "likes",
  user_stat_visits: "visits",

  widget_box_title_insignia: "Badges",
  widget_box_title_activity: "Activity",

  progress_arc_title: "Profile Completion",
  progress_arc_text: "Complete your profile by filling out the profile information fields, completing missions, and unlocking badges",

  achievement_status_title_missions: "Missions",
  achievement_status_text_missions: "Completed",

  achievement_status_title_insignia: "Badges",
  achievement_status_text_insignia: "Unlocked",
}

module.exports = { _lang_perfil };
