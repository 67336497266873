const _lang_product = {
  product_properties: {
    product_title: "Title",
    product_status: "Status",
    product_platform: "Platform",
    product_duration: "Duration",
    product_compatibility: "Compatibility",
    product_days: "Days"
  },
  descript_delivery: "Automatic Delivery",
  descript_delivery_support: "Purchase made with support",
  btn_add_cart: "ADD TO CART",
  btn_buy_with_support: "BUY WITH SUPPORT",
  related_title: "RELATED PRODUCTS",
}

module.exports = { _lang_product };
