const _lang_store = {
  category_list: {
    title_date: "Publication Date",
    title_price: "Price",
  },
  order_list: {
    title_descendente: "Descending",
    title_ascendente: "Ascending"
  },
  section_title: "Store",
  section_pretitle: "SEARCH PRODUCTS",
  section_info_title: "Digital Items",
  section_subsection_title: "Store",
  section_subsection_pretitle: "Digital Items",
  input_items_search: "Search Items",
  label_filter_category: "Filter by",
  label_filter_order: "Sort by",
  siderbar_title: "Categories",
  check_box_category_all: "All Categories",
  btn_aply_filter_by_category: "Apply category filter",
}

module.exports = { _lang_store };
