import React                       from 'react';
import ReactDOM                    from 'react-dom/client';
import App                         from './App';
import { BrowserRouter }           from 'react-router-dom';

console.log(`
██████╗ ██████╗  ██████╗      ██╗███████╗ ██████╗████████╗
██╔══██╗██╔══██╗██╔═══██╗     ██║██╔════╝██╔════╝╚══██╔══╝
██████╔╝██████╔╝██║   ██║     ██║█████╗  ██║        ██║   
██╔═══╝ ██╔══██╗██║   ██║██   ██║██╔══╝  ██║        ██║   
██║     ██║  ██║╚██████╔╝╚█████╔╝███████╗╚██████╗   ██║   
╚═╝     ╚═╝  ╚═╝ ╚═════╝  ╚════╝ ╚══════╝ ╚═════╝   ╚═╝      

██████╗ ███████╗██╗ ██████╗██╗ █████╗ ██╗                
██╔═══██╗██╔════╝██║██╔════╝██║██╔══██╗██║                
██║   ██║█████╗  ██║██║     ██║███████║██║                
██║   ██║██╔══╝  ██║██║     ██║██╔══██║██║                
╚██████╔╝██║     ██║╚██████╗██║██║  ██║███████╗           
 ╚═════╝ ╚═╝     ╚═╝ ╚═════╝╚═╝╚═╝  ╚═╝╚══════╝       
`);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode><BrowserRouter><App /></BrowserRouter></React.StrictMode>);
