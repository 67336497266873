import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const LOGIN_AUTH = async (current_user) => {
  try {
    if(current_user.user_login <= 4) throw new Error("601");
    if(current_user.password <= 8) throw new Error("610");
  } catch (error) {
    const status_code = _lang_base.requests[error.message];
    return { status_code_message: status_code, success: false };
  }

  try {
    const data = new URLSearchParams();
    data.append('log',  current_user.user_login);
    data.append('pwd', current_user.password);
    data.append('g-recaptcha-response', current_user.rpf);
    const _login_auth = await AUTH.post('/routes/login.php', data).then((response) => response.data);
    _login_auth.status_code_message = _lang_base.requests[_login_auth.status_code]
    return _login_auth;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default LOGIN_AUTH;
