import { _remove_storage, storage_vars } from "../Storage/Storage";

function apply_coupon_discont(coupon, cart_total) {
  let discount = 0;
  switch(coupon.discount_type) {
    case "percent":
      discount = (cart_total * coupon.amount) / 100;
      break;
    case "fixed_cart":
      discount = parseFloat(coupon.amount);
      break;
    default:
      break;
  }
  return cart_total - discount;
}

export function gen_coin_type(value, code_coin) {
  let coin_value;
  switch (code_coin) {
    case 'BRL':
      coin_value = 'R$' + value.toFixed(2).replace('.', ',');
      break;
    case 'USD':
      coin_value = '$' + value.toFixed(2);
      break;
    default:
      coin_value = '$' + value.toFixed(2);
  }
  return  coin_value;
}

export function gen_products(Products, _lang_base) {
  Products.vip          = Products.vip.map((item) =>   ({
    ...item,
    product_information: (_lang_base.lang_base === "pt_BR") ? item.product_information_brl : item.product_information_outside
  }));

  Products.free          = Products.free.map((item) =>   ({
    ...item,
    product_information: (_lang_base.lang_base === "pt_BR") ? item.product_information_brl : item.product_information_outside
  }));

  for (let objeto of Products.vip) {
    delete objeto.product_information_brl;
    delete objeto.product_information_outside;
  }

  for (let objeto of Products.free) {
    delete objeto.product_information_brl;
    delete objeto.product_information_outside;
  }

  return Products;
}

export function total_values(products_cart, CouponsInfo) {
  const value_total = products_cart.reduce((acc, ctv) => acc + (ctv.product_amount * ctv.product_information.product_discont), 0.00);
  return (CouponsInfo !== undefined) ? apply_coupon_discont(CouponsInfo, value_total) : value_total;
}

export function type_discont(CouponsInfo) {
  let discount = `${CouponsInfo.amount}%`;
  switch(CouponsInfo.discount_type) {
    case "percent":
      discount = `${CouponsInfo.amount}%`;
      break;
    case "fixed_cart":
      discount = `${CouponsInfo.amount}`;
      break;
    default:
      break;
  }
  return discount;
}

export function fomate_date(date_string) {
  const date    = new Date(date_string);
  const day     = String(date.getDate()).padStart(2, '0');
  const month   = String(date.getMonth() + 1).padStart(2, '0');
  const year    = date.getFullYear();
  const hours   = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function remove_coupon_discont() {
  _remove_storage(storage_vars.coupons);
  window.location.reload();
}
