const _lang_header_top_layout = {
  header_btn: {
    home: "HOME",
    loja: "STORE",
    mods: "MODS",
    discord: "DISCORD",
    faq: "F.A.Q",
    user_is_not_logged_in: "YOU ARE NOT LOGGED IN",
    entrar: "LOGIN",
    atualizar_conta: "UPDATE ACCOUNT",
    vincular_discord: "LINK DISCORD",
    sair: "LOGOUT"
  },
  offCanvas__wrap: {
    title: "NOW YOU CAN ALSO BE A",
    title_dois: " PRO PLAYER"
  },
  timer: {
    recharge: "RECHARGE",
    can_recharge: "AVAILABLE TO RECHARGE",
    timer_text:   "TIME",
    day:     "DAY",
    days:    "DAYS",
    hour:    "HOUR",
    hours:   "HOURS",
    minute:  "MINUTE",
    minutes: "MINUTES",
    time_is_over: "TIME IS OVER"
  },
}

module.exports = { _lang_header_top_layout };
