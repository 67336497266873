import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = (props) => {
  const [verificadorLoading, atuVerificadorLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      atuVerificadorLoading(false);
      document.documentElement.style.overflow = 'auto';
    }, props.timer)
  }, [props.timer])

  return(
    verificadorLoading &&
    <div className="container-loading">
      <Box sx={{ display: 'flex' }}>
        <CircularProgress sx={{color: "white"}} />
      </Box>
    </div> 
  )
}

export default Loading;
