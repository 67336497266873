import { useEffect, useState, useContext, useRef }                from "react";
import { useLocation }                                            from 'react-router-dom';
import ReCAPTCHA                                                  from "react-google-recaptcha";
import LoginEmbed                                                 from "../../Components/LoginEmbed/LoginEmbed";
import { useSnackbar }                                            from 'notistack';
import Backdrop                                                   from '@mui/material/Backdrop';
import CircularProgress                                           from '@mui/material/CircularProgress';
import DISCORDLINKED_AUTH                                         from "../../Utils/AUTH/DISCORDLINKED_AUTH";

import discordIcone                                               from "../../assets/img/icons/discord.png";
import logoProject                                                from "../../assets/img/logo/logo-escuro-hd.png";
import Context                                                    from "../../Provider/Context";
import { _lang_discord, _lang_base }                              from "../../Languages/languages";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Discord = () => {
  const query                                             = useQuery();
  const { UserInfoBase }                                  = useContext(Context);
  const UserInfo                                          = UserInfoBase.Value;

  const access_token                                      = query.get('access_token');
  const refresh_token                                     = query.get('refresh_token');

  const recaptchaRef                                      = useRef();
  const { enqueueSnackbar }                               = useSnackbar();
  const [backdrop_open, set_backdrop_open]                = useState(false);

  const [logoContainerScale, setLogoContainerScale]       = useState(0);
  const [logoDiscordClass, setLogoDiscordClass]           = useState('');
  const [textDiscordClass, setTextDiscordClass]           = useState('');
  const [acceptContainerHeight, setAcceptContainerHeight] = useState(0);
  const [acceptContainerClass, setAcceptContainerClass]   = useState('');
  const [formDivClass, setFormDivClass]                   = useState('');
  const [formH1Class, setFormH1Class]                     = useState('');

  const linked_discord = async (event) => {
    event.preventDefault();

    try {
      set_backdrop_open(true);
      const rpf                   = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_DISCORDLINKED_AUTH  = await DISCORDLINKED_AUTH({ ...UserInfo, rpf, access_token, refresh_token });
      recaptchaRef.current.reset();
      set_backdrop_open(false);

      if(RESULT_DISCORDLINKED_AUTH.success) {
        enqueueSnackbar(RESULT_DISCORDLINKED_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_DISCORDLINKED_AUTH.status_code_message;
        delete RESULT_DISCORDLINKED_AUTH.success;
        delete RESULT_DISCORDLINKED_AUTH.status_code;

        UserInfoBase.Update(RESULT_DISCORDLINKED_AUTH);

        return;
      }
      enqueueSnackbar(RESULT_DISCORDLINKED_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLogoContainerScale(1);
      setTimeout(() => {
        setLogoDiscordClass('loadIn');
        setTimeout(() => {
          setTextDiscordClass('loadIn');
          setTimeout(() => {
            setAcceptContainerHeight('431.5px');
            setTimeout(() => {
              setAcceptContainerClass('loadIn');
              setTimeout(() => {
                setFormDivClass('loadIn');
                setFormH1Class('loadIn');
              }, 500);
            }, 500);
          }, 500);
        }, 500);
      }, 1000);
    }, 10);
  }, []);

  useEffect(() => {
    if (!UserInfo) return;
    if(access_token === undefined || 
      access_token === null       || 
      refresh_token === undefined || 
      refresh_token === null) return window.location = process.env.REACT_APP_LINK_LINKED_DISCORD
  }, [UserInfo, access_token, refresh_token]);

  if (!UserInfo || UserInfo.user === undefined) return <LoginEmbed />;

  return(
  <>
    <Backdrop
      sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdrop_open}
    >
      <CircularProgress color="inherit" />
      <div className="checkout-text"><p>{_lang_discord.generating_purchase}</p></div>
    </Backdrop>
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
    />
    {!UserInfo.user_discord ?
    <main>
      <div id="container-discord">
        <div id="inviteContainer">
          <div className="logoContainer" style={ { transform: `scale(${logoContainerScale})` } }>
            <img className={`logo-discord ${logoDiscordClass}`} src={discordIcone} alt={_lang_base.web_site_name} />
            <img className={`text-discord ${textDiscordClass}`} src={logoProject} alt={_lang_base.web_site_name} />
          </div>
          <div style={{ height: acceptContainerHeight }} className={`acceptContainer ${acceptContainerClass}`}>
            <form>
              <h1 className={`loadIn ${formH1Class}`}>{_lang_discord.header_linked}</h1>
              <div className="formContainer">
                <div className={`formDiv ${formDivClass}`}>
                  <button onClick={linked_discord} className="acceptBtn" type="submit">{_lang_discord.btn_linked}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
    :
    <main>
      <div id="container-discord">
        <div id="inviteContainer">
          <div className="logoContainer" style={ { transform: `scale(${logoContainerScale})` } }>
            <img className={`logo-discord ${logoDiscordClass}`} src={discordIcone} alt={_lang_base.web_site_name} />
            <img className={`text-discord ${textDiscordClass}`} src={logoProject} alt={_lang_base.web_site_name} />
          </div>
          <div style={{ height: acceptContainerHeight }} className={`acceptContainer ${acceptContainerClass}`}>
            <h1 className={`loadIn ${formH1Class}`}>{_lang_discord.msg_linked}</h1>
          </div>
        </div>
      </div>
    </main>
    }
  </>
  )
}

export default Discord;
