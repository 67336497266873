const _lang_menus = {
  not_have_notifications: "Não contem nenhuma notificação.",
  cart_title: "Carrinho:",
  cart_total_title: "Total:",
  btn_cart: "Carrinho",
  btn_finalize_purchase: "Finalizar compra",
  notication_title: "Notificações",
  btn_brand_everything_as_read: "Marcar tudo como lido",
  btn_configuration: "Configurações",
  btn_see_all_notifications: "Ver todas as notificações",

  user_header_btn: {
    profile: "Perfil",
    orders: "Pedidos",
    settings: "Configurações",
    logout: "Sair"
  }
}

module.exports = { _lang_menus };
