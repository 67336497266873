import React, { useState, useEffect } from 'react';

function Cronometro({ order_time_expire }) {
  const [time_left, set_time_left] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const current_time = Math.floor(Date.now() / 1000);
      set_time_left(order_time_expire - current_time);
      if (order_time_expire <= current_time) clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [order_time_expire]); 

  const format_time = (segundos) => {
    const hours   = Math.floor(segundos / 3600);
    const minutes = Math.floor((segundos % 3600) / 60);
    const seconds = segundos % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div><p>{format_time(time_left)}</p></div>
  );
}

export default Cronometro;
