const _lang_terms_of_use = {
  breadcrumb_item: "Home",
  title: "Terms of Use",
  sub_title: "FOLLOW ALL RULES",
  title_sub: "RULES",
  context: "To have a good environment and a pleasant community for everyone, we need to follow some rules!!",
  informacacoes: [
    {
      titulo: "Any kind of prejudice is prohibited",
      texto: `Any opinion or feeling conceived without critical examination.
      hostile feeling, assumed as a result of the hasty generalization of a personal experience or imposed by the environment; intolerance.
      "p. against a religious, national, or racial group"`
    },
    {
      titulo: "Any kind of pornographic content is prohibited",
      texto: `Study of prostitution. collection of obscene paintings or engravings.`
    },
    {
      titulo: "Posting viruses/malware is prohibited",
      texto: `A malicious code, malicious program, harmful software, or malware, is a computer program intended to infiltrate someone else's computer system unlawfully, with the aim of causing some damage, alterations, or theft of information.`
    },
    {
      titulo: "Purchase Policy",
      texto: `As soon as you make the purchase of the software, you have up to 2 hours to request a refund using the software or not, after that time the refund will not be made.
      The refund is only issued if all possible tests are performed to work on the user's PC, if in no way it works, the refund is made.`
    },
    {
      titulo: "We are not responsible for the security of your account...",
      texto: `We guarantee the best service in all our mods and bypass, but the risk is entirely the user's, if you are penalized by the game, there is no point in complaining to us ADMINISTRATORS or complaining in chats. Remember that nobody forced you to use the mods.`
    },
    {
      titulo: "You are responsible for the security of your account",
      texto: `If you lose or are hacked, it is the user's responsibility to take care of their own account, so be careful.`
    },
    {
      titulo: "You are responsible for the content posted on your account",
      texto: `Any penalty imposed by your account will be your responsibility, and any measures such as bans or suspensions will not be claimed.`
    },
    {
      titulo: "You cannot register more than one (1) account on our site",
      texto: `We seek to maintain our member control, so we ask and put this rule so that you do not create more than one account and keep your personal account.`
    },
    {
      titulo: "Respect others' privacy, do not share information...",
      texto: `Do not share or post personal information on the site or discord, this will result in immediate banning, the group was not formed for that.`
    },
    {
      titulo: "No advertising, marketing, or promotion of any kind",
      texto: `Advertising is a professional activity dedicated to the public dissemination of companies, products, or services. It can be qualified as "commercial advertising". It is the dissemination of products, services, and ideas to the public, aiming to induce it to a favorable dynamic attitude.`
    },
    {
      titulo: "Spam is prohibited in our chats/calls and in the dm of our members or on the site",
      texto: `SPAM is the practice of using electronic means to send unsolicited messages. In general, the goal of SPAM is to advertise products and services, but also to apply scams, spread rumors, and spread malicious software. Its name is based on a Monty Python skit.`
    },
    {
      titulo: "Use the channels correctly",
      texto: `Using the channels in the right way and posting in the right area, using the doubts area responsibly without breaking any other rules.`
    },
    {
      titulo: "Any kind of gore content is prohibited",
      texto: `Splatter or gore is a subgenre of horror cinema that deliberately focuses on graphic representations of blood and violence. These films, through the use of special effects, tend to present an evident interest in the vulnerability of the human body and its theatrical mutilation.`
    },
  ]  
}

module.exports = { _lang_terms_of_use };
