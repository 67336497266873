const _lang_product = {
  product_properties: {
    product_title: "Título",
    product_status: "Status",
    product_platform: "Plataforma",
    product_duration: "Duração",
    product_compatibility: "Compatibilidade",
    product_days: "Dias"
  },
  descript_delivery: "Entrega automática",
  descript_delivery_support: "Compra feita com o suporte",
  btn_add_cart: "ADICIONAR NO CARRINHO",
  btn_buy_with_support: "COMPRAR COM O SUPORTE",
  related_title: "PRODUTOS RELACIONADOS",
}

module.exports = { _lang_product };
