import Skeleton from "@mui/material/Skeleton";

function LoadingComponent() {
  return (
    <Skeleton
      sx={{ bgcolor: 'grey.900' }}
      variant="rectangular"
      width="100%"
      height="100%"
    />
  )
}

export default LoadingComponent;
