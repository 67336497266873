const _lang_requests = {
  "error": "Contact an administrator",
  "not_logged_in": "You are not logged in",

  "447": "Successfully synchronized",
  "501": "Invalid credentials",
  "502": "Logged in successfully",

  "701": "You cannot recharge",
  "704": "Successfully recharged",

  "600": "User with invalid characters",
  "601": "User too short",
  "602": "User too long",
  "610": "Password too short",
  "611": "Password too long",
  "612": "Different passwords",
  "603": "Invalid email",
  "604": "Invalid email",
  "605": "Email already in use",
  "606": "User already in use",
  "607": "Error creating account",
  "608": "Account created successfully",

  "613": "Invalid first name",
  "614": "Invalid second name",
  "615": "Cpf invalid",

  "801": "Invalid key",
  "806": "Key activated successfully",
  
  "410": "Very short information",
  "411": "Error when reporting problem",
  "412": "Problem reported successfully",

  "906": "Error when generating your order",
  "907": "Request generated successfully",
  "914": "Unauthorized transaction",
  "915": "Invalid card",
  "916": "Invalid card date",
  "917": "Invalid card holder",
  "918": "CVV of invalid card",
  "919": "Coupon is invalid",
  "920": "Coupon is expired",
  "921": "Coupon has already been used",
  "922": "Coupon activated successfully",
  "925": "Payment made successfully",
  "926": "Order canceled successfully",

  "300": "Invalid email",
  "303": "Invalid information",
  "304": "Generate a new code",
  "309": "Email sent successfully, check your inbox",
  "310": "Password changed successfully",

  "913": "Synchronized orders" ,

  "311": "Invalid Discord",
  "312": "Could not link",
  "313": "Could not link",
  "320": "Discord linked successfully",
}

module.exports = _lang_requests;
