const _lang_recharge = {
  like_video: "CURTIR VIDEO",
  follow_channel: "SEGUIR CANAL",
  btn_cancel: "CENCELAR",
  btn_next: "PROXIMO",
  sub_title: "Ganhar não é mais uma questão de sorte. Com nosso sistema, você pode ganhar de forma consistente.",
  btn_recharge: "FAZER RECARGA",

  text_verify_waiting: "Aguardando...",
  text_verify_follown: "Verificando se você seguiu",
  text_verify_enjoy: "Verificando se você curtiu",

  msg_verify_success: "Verificação feita com sucesso",
  msg_error_adblock: "Adblock está ativado, desative-o",
}

module.exports = { _lang_recharge };
