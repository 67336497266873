import { useState, useContext, useRef }               from "react";

import Context                                        from "../../Provider/Context";

import ReCAPTCHA                                      from "react-google-recaptcha";
import { useSnackbar }                                from 'notistack';

import Backdrop                                       from '@mui/material/Backdrop';
import CircularProgress                               from '@mui/material/CircularProgress';
import Button                                         from "@mui/material/Button";
import TextField                                      from "@mui/material/TextField";
import DialogActions                                  from "@mui/material/DialogActions";
import DialogContent                                  from "@mui/material/DialogContent";
import DialogContentText                              from "@mui/material/DialogContentText";
import DialogTitle                                    from "@mui/material/DialogTitle";
import ReportProblemIcon                              from "@mui/icons-material/ReportProblem";

import REPORTPROBLEM_AUTH                             from "../../Utils/AUTH/REPORTPROBLEM_AUTH";

/* IMPORT LANGUAGE */
import { _lang_report_problem, _lang_base }           from "../../Languages/languages";

function ReportProblem(props) {
  const { UserInfoBase, Chat }                        = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  const [problem_text, set_problem_text]              = useState("");

  const recaptchaRef                                  = useRef();
  const { enqueueSnackbar }                           = useSnackbar();
  const [backdrop_open, set_backdrop_open]            = useState(false);

  const report_ploblem_function = async () => {
    try {
      set_backdrop_open(true);
      const rpf                   = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_REPORTPROBLEM_AUTH  = await REPORTPROBLEM_AUTH({ ...UserInfo, rpf, product_id: props.product_id, report: problem_text });
      recaptchaRef.current.reset();
      set_backdrop_open(false);
      props.set_dialog_menu_open(false);

      if(RESULT_REPORTPROBLEM_AUTH.success) {
        enqueueSnackbar(RESULT_REPORTPROBLEM_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_REPORTPROBLEM_AUTH.status_code_message;
        delete RESULT_REPORTPROBLEM_AUTH.success;
        delete RESULT_REPORTPROBLEM_AUTH.status_code;

        UserInfoBase.Update(RESULT_REPORTPROBLEM_AUTH);
        Chat.Update(true);
        return;
      }
      enqueueSnackbar(RESULT_REPORTPROBLEM_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
      props.set_dialog_menu_open(false);
      Chat.Update(true);
    }
  }

  return(
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
      />
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="profile">
        <DialogTitle><ReportProblemIcon /> {_lang_report_problem.dialog_title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{_lang_report_problem.dialog_context}</DialogContentText>
          <TextField
            onChange={(e) => set_problem_text(e.target.value)}
            value={problem_text}
            multiline
            rows={10}
            fullWidth
            sx={{marginTop: "10px"}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.set_dialog_menu_open(false)} >{_lang_report_problem.btn_cancel}</Button>
          <Button onClick={report_ploblem_function} type="submit">{_lang_report_problem.btn_send}</Button>
        </DialogActions>
      </div>
    </>
  )
}

export default ReportProblem;
