const _lang_lost_password = {
  tile_password: "Esqueceu sua senha?",
  sub_title: "Ok, não entre em pânico. Você pode recuperá-lo aqui",
  btn_email: "Enviar Solicitação",

  sub_title_two: "Agora digite a senha que deseja duas vezes",
  btn_change_password: "Alterar Senha",

  place_user: "Email",
  place_password: "Digite sua nova senha",
  place_sub_password: "Digite novamente sua nova senha",
}

module.exports = { _lang_lost_password };
