const _lang_refund_policy = {
  title_header: "Refund Policy",
  sub_title: "READ EVERYTHING BEFORE MAKING THE PURCHASE",
  title: "Partial or full refund of the purchase of our mods.",
  texto: "Consumable products are non-refundable, as well as products where it is impossible to know if the user has copies of the software. If you do not agree with this term, do not buy products of this kind. Reviewing: you will not be entitled to a refund in the case of consumable products (game keys) and in products where you own the Release (single download digital products):",
  blockquote_geral: [
    {
      cite: "Policy I",
      texto: `A target computer is not owned by a user or a user has not received consent from the owner of a target computer to install the purchased Software (provided for only one HWID);`
    },
    {
      cite: "Policy II",
      texto: `The target computer runs an unsupported operating system. Supported operating systems are on the help page on our dashboard.`
    },
    {
      cite: "Policy III",
      texto: `A user does not follow the installation instructions received by our customer support team and does not accept support via remote access tool;`
    },
    {
      cite: "Policy IV",
      texto: `A user does not accept technical assistance of any kind;`
    },
    {
      cite: "Policy V",
      texto: `A user intended to use the Official Project on more than one computer;`
    },
    {
      cite: "Policy VI",
      texto: `Personal reasons (I changed my mind, I made a purchase by mistake, the Software was not used, I do not wish to wait for updates, etc.);`
    },
    {
      cite: "Policy VII",
      texto: `No access to the target computer (I lost / damaged my computer, I will no longer use it, etc.);`
    },
    {
      cite: "Policy VIII",
      texto: `A target computer does not have an Internet connection;`
    },
    {
      cite: "Policy IX",
      texto: `A user's target computer does not comply with Official Project compatibility requirements;`
    },
    {
      cite: "Policy X",
      texto: `The Official Project Software has been unlinked or damaged by antivirus software or by the owner of the target device;`
    },
    {
      cite: "Policy XI",
      texto: `A target computer has lost connection to the Official Project subscription due to factory reset or operating system update.`
    },
    {
      cite: "Policy XII",
      texto: `The user has received game bans, or any other bans not related to Valve Antimod;`
    },
    {
      cite: "Policy XIII",
      texto: `The user has been banned but cannot prove their relationship with the use of the Official Project;`
    },
    {
      cite: "Policy XVI",
      texto: `The user refuses to provide further evidence in case of suspicion of fraudulent evidence;`
    }
  ],
  textos_importantes: [
    {
      titulo: "RELATED TO CHARGEBACK",
      texto: `In case of a chargeback by a credit card company (or similar action by another payment provider allowed by us) related to your purchase of any subscription plan, you agree that we may suspend access to any of the accounts you have with us.
      In case of refund issuance, please note that for each transaction you may be deducted a fee from the refunded amount to cover the fees charged by banks and payment processing companies.
      We do not accept refund requests via Chat or by any means other than the one referred to here.
      We do not accept refund requests that lack one or more of the data requested here, and may not be answered.
      The refund decision will be made within seven business days.`,
    },
    {
      titulo: "RELATED TO REQUEST",
      texto: `To request a refund, be aware of the Terms and Conditions of Use.
      The refund will only be provided if it is duly proven the link of the Official Project with the bans by instant detection of the Anti-mod. Any other type of ban is not eligible for a refund, as well as after the end of the product warranty period the refund cannot be requested anymore, because the user has already used it which in turn complied with the product sales terms.`,
    },
    {
      titulo: "RELATED TO NONCOMPLIANCE",
      texto: `Noncompliance with our terms will result in a permanent ban on your account, if you wish to make a partial refund you will have a fine of 1/3 of the value, if you open a claim with intermediaries we will contest it with our evidence and you will lose the right to partial refund.`,
    },
    {
      titulo: "RELATED TO EVIDENCE",
      texto: `When a mod is known by the system, the antimod causes instant ban at the moment of activation. This is, and only this is the guarantee that is provided and eligible for refund if duly proven.`,
    },
    {
      titulo: "RELATED TO PROCEDURE",
      texto: `Send an email to suporte@projectoficial.com containing the link or video file of the evidence, as well as the attachments and request data, such as full name, email address of payment and website registration, date of purchase, product requesting the refund. Other evidence may be attached and favor your refund case.`,
    },
  ],
  blockquote_banimento: [
    {
      cite: "Banning Policy I",
      texto: `Create a new account, perform the injection and prove that activation results in banning. All these steps properly recorded without interruptions or cuts in the video.`
    },
    {
      cite: "Banning Policy II",
      texto: `Always record the mod activation, either by screen recording software or by cellphone.`
    }
  ],
  texto_final: `Only the previous paragraphs are accepted as evidence, other files may be considered auxiliary in the case, but it is necessary that item I or II are contained in the request.
  Be aware that not sending this information in the request may result in the invalidation of your request, which may not even be answered.`,
  breadcrumb_item: "Home"
}

module.exports = { _lang_refund_policy };
