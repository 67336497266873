const _lang_perfil    = {
  user_stat_post: "posts",
  user_stat_likes: "curtidas",
  user_stat_visits: "visitas",

  widget_box_title_insignia: "Insígnias",
  widget_box_title_activity: "Atividade",

  progress_arc_title: "Conclusão do perfil",
  progress_arc_text: "Complete seu perfil preenchendo os campos de informações do perfil, completando missões e desbloqueando emblemas",

  achievement_status_title_missions: "Missões",
  achievement_status_text_missions: "Concluído",

  achievement_status_title_insignia: "Insígnias",
  achievement_status_text_insignia: "Desbloqueado",
}

module.exports = { _lang_perfil };
