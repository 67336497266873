import { useEffect, useState, useContext }  from "react";

import Context                              from "../../Provider/Context";

import LoadingComponent                     from "../../Components/Common/LoadingComponent";
import Footer                               from "../../Components/Footer/Footer";

/* IMPORT IMAGES */
import TeamDetailsBG                        from "../../assets/img/bg/team_details_bg.jpg";

function Faq() {
  const { FaqVideos }                       = useContext(Context);
  const [loading_faq, set_loading_faq]      = useState(true);

  useEffect(() => { setTimeout(() => set_loading_faq(false) , 5000); }, []);
  useEffect(() => {window.scrollTo({top: 0,behavior: 'smooth'});}, []);
  
  const component_video_container_loading = FaqVideos.support.map((item, index) => 
    <div style={{display: (loading_faq) ? "block" : "none"}} key={`loading_faq_${index}`} className="col-sm-6">
      <div className="team__details-inner-img">
        <div className="video-suporte video-suporte-v2">
          <LoadingComponent  />
        </div>
      </div>
    </div>
  )

  const component_video_container = FaqVideos.support.map((item, index) => 
    <div style={{display: (loading_faq) ? "none" : "block"}} key={index} className="col-sm-6">
      <div className="team__details-inner-img">
        <iframe className="video-suporte" src={item.video_link} 
          title="YouTube video player" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen 
        />
      </div>
    </div>
  )

  return (
    <>
      <main className="main--area">
        <section className="services__details-area section-pt-120 section-pb-120 faq-section" style={{ backgroundImage: `url(${TeamDetailsBG})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                      <div className="team__details-inner-wrap">
                        <div className="row">{component_video_container_loading}{component_video_container}</div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Faq;
