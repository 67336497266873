const _lang_checkout_pay = {
  nao_logado: "You are not logged in",
  contactar_administrador: "Contact an administrator",
  efetue_o_pagamento: "Make the payment",
  btn_loja: "Store",
  descricao_vazio: "Cart is empty",

  header_pedido: "Order",
  data: "Date",

  formas_de_pagamento: {
    pix: {
      como_pagar: "How to pay with Pix:",
      info_um: "Access your bank's app or website",
      info_dois: "Look for the option to pay with Pix",
      info_tres: "Read the QR code or Pix code",
      infor_quatro: "Done! You will see the payment confirmation",
      esquenei_o_qr: "Scan the QR code:",
      codigo_valido: "Code valid for 60 minutes",
      se_preferir: "If you prefer, you can pay by copying and pasting the following code",
      btn_pagamento: "Copy code",
      msg_codigo_copiado: "Copied pix code",
    },
    mercado_pago: {
      como_pagar: "How to pay with Mercado Pago:",
      info_um: "Access the link by clicking the button",
      info_dois: "Fill in your details",
      info_tres: "Make the payment",
      btn_pagamento: "Open Mercado Pago payment link",
      btn_copiar: "Copy Mercado Pago payment link",
      msg_codigo_copiado: "Copied payment link",
    },
    stripe: {
      como_pagar: "How to pay with Stripe:",
      info_um: "Access the link by clicking the button",
      info_dois: "Fill in your details",
      info_tres: "Make the payment",
      btn_pagamento: "Open Stripe payment link",
      btn_copiar: "Copy Stripe payment link",
      msg_codigo_copiado: "Copied payment link",
    },
  },
  valor_a_pagar: "Amount to pay: ",
  tempo_restante: "Time remaining for payment:",
  btn_cancell_order: "Cancel Order",
};

module.exports = { _lang_checkout_pay };
