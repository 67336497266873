const _lang_profile_options = {
  payment_ids: {
    "0": "Inválido",
    "1": "PIX",
    "2": "PIX",
    "3": "STRIPE",
    "4": "MERCADO PAGO",
    "5": "CARTÃO DE CRÉDITO",
  },
  payment_status: {
    "completed": "Completo",
    "pending":   "Pendente",
    "cancelled": "Cancelado",
    "on-hold":   "Aguardando",
    "failed":    "Fracassado",
  },

  timer_expired: "O tempo de pagamento expirou",
  order_cancelled: "Esse pedido está cancelado",
  order_completed: "Pagamento concluído",
}

module.exports = { _lang_profile_options };
