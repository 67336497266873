import { useState, useContext, useRef, useEffect }               from "react";
import { useNavigate }                                           from "react-router-dom";
import ReCAPTCHA                                                 from "react-google-recaptcha";

import CreateSvg                                                 from "../../Components/Common/CreateSvg";
import AcceptTerms                                               from "../../Components/AcceptTerms/AcceptTerms";
import Context                                                   from "../../Provider/Context";
import CartIsEmpty                                               from "../../Components/CartIsEmpty/CartIsEmpty";

import { gen_coin_type, total_values, type_discont, 
  remove_coupon_discont }                                        from "../../Utils/StoreFunction";

import { useSnackbar }                                           from 'notistack';
import Tooltip                                                   from "@mui/material/Tooltip";
import Backdrop                                                  from '@mui/material/Backdrop';
import CircularProgress                                          from '@mui/material/CircularProgress';

import FormControl                                               from '@mui/material/FormControl';
import FormGroup                                                 from '@mui/material/FormGroup';
import FormControlLabel                                          from '@mui/material/FormControlLabel';
import FormHelperText                                            from '@mui/material/FormHelperText';
import Checkbox                                                  from '@mui/material/Checkbox';

/* IMPORT IMAGES */
import marketplace_icon                                          from "../../assets/img/icons/market_place/marketplace_icon.png"
import pix_icon                                                  from "../../assets/img/icons/payment_methods/pix.png";
// import mercado_pago_icon                                         from "../../assets/img/icons/payment_methods/mercadopago.png";
// import stripe_icon                                               from "../../assets/img/icons/payment_methods/stripe.png";
import credit_card_icon                                          from "../../assets/img/icons/payment_methods/creditcard.png";

/* IMPORT LANGUAGE */
import { useSynchronizeAccount }                                 from "../../Hooks/SynconizeAccount";
import CHECKOUT_AUTH                                             from "../../Utils/AUTH/CHECKOUT_AUTH";
import CHECKCOUPON_AUTH                                          from "../../Utils/AUTH/CHECK_COUPON";
import { _lang_checkout, _lang_base }                            from "../../Languages/languages";

const payment_methods = [
  // {
  //   payment_title: _lang_checkout.payment_methods.mercado_pago.payment_title,
  //   payment_id: _lang_checkout.payment_methods.mercado_pago.payment_id,
  //   payment_description: _lang_checkout.payment_methods.mercado_pago.payment_description,
  //   payment_icon: mercado_pago_icon,
  //   payment_currency: "pt_BR",
  // },
  {
    payment_title: _lang_checkout.payment_methods.pix_asaas.payment_title,
    payment_id: _lang_checkout.payment_methods.pix_asaas.payment_id,
    payment_description: _lang_checkout.payment_methods.pix_asaas.payment_description,
    payment_icon: pix_icon,
    payment_currency: "pt_BR",
  },
  {
    payment_title: _lang_checkout.payment_methods.stripe.payment_title,
    payment_id: _lang_checkout.payment_methods.stripe.payment_id,
    payment_description: _lang_checkout.payment_methods.stripe.payment_description,
    payment_icon: credit_card_icon,
    payment_currency: "en,pt_BR",
  },
  
  // {
  //   payment_title: _lang_checkout.payment_methods.mercado_pago.payment_title,
  //   payment_id: _lang_checkout.payment_methods.mercado_pago.payment_id,
  //   payment_description: _lang_checkout.payment_methods.mercado_pago.payment_description,
  //   payment_icon: mercado_pago_icon,
  //   payment_currency: "pt_BR",
  // },

  // {
  //   payment_title: _lang_checkout.payment_methods.asaas_credit.payment_title,
  //   payment_id: _lang_checkout.payment_methods.asaas_credit.payment_id,
  //   payment_description: _lang_checkout.payment_methods.asaas_credit.payment_description,
  //   payment_icon: credit_card_icon,
  //   payment_currency: "pt_BR",
  // },
]

const current_time    = Math.floor(Date.now() / 1000);

function Checkout() {
  const history                                                   = useNavigate();
  const { ProductsCart, UserInfoBase, UserCheckout, 
    Coupons }                                                     = useContext(Context);
  const [payment_method, set_payment_method]                      = useState((_lang_base.lang_base === "pt_BR") ? _lang_checkout.payment_methods.pix_asaas.payment_id : _lang_checkout.payment_methods.stripe.payment_id);

  const recaptchaRef                                              = useRef();
  const { enqueueSnackbar }                                       = useSnackbar();
  // const [backdrop_open, set_backdrop_open]                 = useState(false);

  const [accept_terms, set_accept_terms]                          = useState(false);
  const [accept_terms_error, set_accept_terms_error]              = useState(false);
  const [accept_terms_open, set_accept_terms_open]                = useState(false);

  const [input_first_name, set_input_first_name]                  = useState("");
  const [input_last_name, set_input_last_name]                    = useState("");
  const [input_email, set_input_email]                            = useState("");
  const [input_cpf, set_input_cpf]                                = useState("");

  const [payment_credit_number, set_payment_credit_number]        = useState("");
  const [payment_credit_valid, set_payment_credit_valid]          = useState("");
  const [payment_credit_name, set_payment_credit_name]            = useState("");
  const [payment_credit_code, set_payment_credit_code]            = useState("");
  const [coupon_code, set_coupon_code]                            = useState("");
  const { synchronizeAccount, backdrop_open, set_backdrop_open }  = useSynchronizeAccount();

  const UserInfo                                                  = UserInfoBase.Value;
  const OrderInfo                                                 = UserCheckout.Value;
  const products_cart                                             = ProductsCart.Value;
  const CouponsInfo                                               = Coupons.Value;

  const value_total                                               = total_values(products_cart, CouponsInfo);
  const value_total_no_discont                                    = total_values(products_cart, undefined);

  const accept_terms_checked = () => {
    if(accept_terms) return;
    set_accept_terms_open(true);
  };

  useEffect(() => {
    if(OrderInfo && OrderInfo.order_time_expire >= current_time) history(`/checkout/${OrderInfo.order_id}`);
  }, [OrderInfo, history]);

  if(OrderInfo && OrderInfo.order_time_expire >= current_time) return <></>;
  if(products_cart.length <= 0) return <CartIsEmpty />;

  const component_products_cart                      = products_cart.map((item, index) => 
    <div key={index} className="totals-line">
      <div className="totals-line-info">
        <p className="totals-line-title"><span className="bold">{item.product_information.product_title}</span></p>
        <p className="totals-line-text">{item.product_information.product_category}</p>
      </div>
      <p className="price-title">{gen_coin_type(item.product_information.product_discont, _lang_base.currency)} x {item.product_amount}</p>
    </div>
  );

  const component_payment_method                     = payment_methods.map((item, index) => 
    <div key={index} className="checkbox-wrap">
      <input 
        type="radio" 
        id={item.payment_id} 
        name="payment_type" 
        value={item.payment_id}
        onChange={() => set_payment_method(item.payment_id)}
        checked={(item.payment_id === payment_method) ? true : false}
        disabled={(!item.payment_currency.includes(_lang_base.lang_base))}
      />
      <div className="checkbox-box round"></div>
        <Tooltip title={(!item.payment_currency.includes(_lang_base.lang_base)) ? _lang_checkout.payment_released : ""} placement="left" arrow>
          <label className="accordion-trigger-linked" htmlFor={item.payment_id}>{item.payment_title} <img src={item.payment_icon} alt={_lang_base.web_site_name} /></label>
        </Tooltip>
      {(payment_method === item.payment_id) &&       
        <div className="checkbox-info accordion-content-linked">
          <p className="checkbox-info-text">{item.payment_description}</p>
        </div>
      }
    </div>
  );

  const component_payment_credit                     = (
    <div className="widget-box">
      <p className="widget-box-title">{_lang_checkout.widget_title_credit}</p>
      <div className="widget-box-content">
        <div className="form">
          <div className="form-row split form-row-credit">
            <div className="form-item form-credit">
              <div className="form-input small">
                <input 
                  value={payment_credit_number} 
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, '');
                    value = value.substring(0, 16);
                    const formattedValue = value.replace(/(.{4})/g, '$1 ').trim();
                    set_payment_credit_number(formattedValue);
                  }}
                  placeholder={_lang_checkout.inputs_users_credit.input_credit_number} 
                  type="text" 
                  id="billing-first-name" 
                  name="billing_first_name" 
                />
              </div>
            </div>
            <div className="form-item form-credit">
              <div className="form-input small">
                <input 
                  value={payment_credit_valid} 
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, '');
                    if(value.length >= 5)  return;
                    if (value.length >= 3) value = value.slice(0, 2) + '/' + value.slice(2);
                    set_payment_credit_valid(value);
                  }}
                  placeholder={_lang_checkout.inputs_users_credit.input_credit_valid} 
                  type="text" 
                  id="billing-last-name" 
                  name="billing_last_name" 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-box-content">
        <div className="form">
          <div className="form-row split form-row-credit">
            <div className="form-item form-credit">
              <div className="form-input small">
                <input 
                  value={payment_credit_name} 
                  onChange={(e) => set_payment_credit_name(e.target.value)} 
                  placeholder={_lang_checkout.inputs_users_credit.input_credit_name} 
                  type="text" 
                  id="billing-first-name" 
                  name="billing_first_name" 
                />
              </div>
            </div>
            <div className="form-item form-credit">
              <div className="form-input small">
                <input 
                  value={payment_credit_code} 
                  onChange={(e) => {
                    const value =  e.target.value.replace(/\D/g, '');
                    if(value.length > 3) return;
                    set_payment_credit_code(value);
                  }}
                  placeholder={_lang_checkout.inputs_users_credit.input_credit_code} 
                  type="text" 
                  id="billing-last-name" 
                  name="billing_last_name" 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="title_information">{_lang_checkout.title_information}</p>
    </div>
  );

  const function_checkout      = async () => {
    if(!UserInfo) {
      enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
      return history("/login");
    }
    if(!accept_terms) {
      enqueueSnackbar(_lang_base.accept_terms_error, {variant: 'error'});
      set_accept_terms_error(true);
      return;
    }

    try {
      set_backdrop_open(true);
      const rpf                   = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_CHECKOUT_AUTH  = await CHECKOUT_AUTH({ 
        ...UserInfo, payment_method, ...CouponsInfo,
        products_cart, rpf, 
        payment_currency: _lang_base.currency, 
        payment_data: { 
          payment_first_name: input_first_name, 
          payment_last_name:  input_last_name,
          payment_email:      input_email,
          payment_cpf:        input_cpf,
        },
        payment_credit: {
          payment_credit_number,
          payment_credit_valid,
          payment_credit_name,
          payment_credit_code,
        }
      });
      recaptchaRef.current.reset();
      set_backdrop_open(false);
      
      if(RESULT_CHECKOUT_AUTH.success) {
        enqueueSnackbar(RESULT_CHECKOUT_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_CHECKOUT_AUTH.status_code_message;
        delete RESULT_CHECKOUT_AUTH.success;
        delete RESULT_CHECKOUT_AUTH.status_code;

        if(RESULT_CHECKOUT_AUTH.order_details.order_status === "completed") {
          await synchronizeAccount();
          ProductsCart.Update([]);
          UserCheckout.Update([]);
          history('/ui');
          return;
        } else {
          ProductsCart.Update([]);
          UserCheckout.Update(RESULT_CHECKOUT_AUTH.order_details);
          history(`${RESULT_CHECKOUT_AUTH.order_details.order_id}`);
          return;
        }
      }
      enqueueSnackbar(RESULT_CHECKOUT_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  const add_coupon = async () => {
    if(!UserInfo) {
      enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
      return history("/login");
    }

    if(CouponsInfo !== undefined) {
      enqueueSnackbar(_lang_base.requests["921"], {variant: 'error'});
      return;
    }

    try {
      set_backdrop_open(true);
      const rpf                          = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_CHECKOUTCOUPON_AUTH   = await CHECKCOUPON_AUTH({ ...UserInfo, coupon_code, rpf });
      recaptchaRef.current.reset();
      set_backdrop_open(false);

      if(RESULT_CHECKOUTCOUPON_AUTH.success) {
        enqueueSnackbar(RESULT_CHECKOUTCOUPON_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_CHECKOUTCOUPON_AUTH.status_code_message;
        delete RESULT_CHECKOUTCOUPON_AUTH.success;
        delete RESULT_CHECKOUTCOUPON_AUTH.status_code;

        Coupons.Update(RESULT_CHECKOUTCOUPON_AUTH.coupon_info);
        return;
      }
      enqueueSnackbar(RESULT_CHECKOUTCOUPON_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  return (
    <>
      <AcceptTerms
        set_accept_terms={set_accept_terms}
        accept_terms_open={accept_terms_open}
        set_accept_terms_open={set_accept_terms_open}
        set_accept_terms_error={set_accept_terms_error}
      />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
      />

      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
        <div className="checkout-text"><p>{_lang_checkout.generating_purchase}</p></div>
      </Backdrop>

      <div className="container profile">
        <div className="content-grid">
          <div className="section-banner">
            <img className="section-banner-icon" src={marketplace_icon} alt={_lang_base.web_site_name} />
            <p className="section-banner-title">{_lang_checkout.section_title}</p>
          </div>
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">{_lang_checkout.section_pretitle}</p>
              <h2 className="section-title">{_lang_checkout.section_info_title}</h2>
            </div>
          </div>
          
          <div className="grid grid-8-4 small-space">
            <div className="grid-column">
              <div className="promo-line">
                <p className="promo-line-text">{_lang_checkout.text_discont_message}</p>
                <div className="promo-line-actions">
                  <div className="form-input small">
                    <input 
                      type="text" 
                      id="promo-code" 
                      name="promo_code" 
                      value={coupon_code}
                      onChange={(e) => set_coupon_code(e.target.value)}
                    />
                  </div>
                  <p onClick={add_coupon} className="button secondary">{_lang_checkout.btn_discont}</p>
                </div>
              </div>
              <div className="widget-box">
                <p className="widget-box-title">{_lang_checkout.widget_title}</p>
                <div className="widget-box-content">
                  <div className="form">
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small">
                          <input 
                            value={input_first_name} 
                            onChange={(e) => set_input_first_name(e.target.value)} 
                            placeholder={_lang_checkout.inputs_users.input_first_name} 
                            type="text" 
                            id="billing-first-name" 
                            name="billing_first_name" 
                          />
                        </div>
                      </div>
                      <div className="form-item">
                        <div className="form-input small">
                          <input 
                            value={input_last_name} 
                            onChange={(e) => set_input_last_name(e.target.value)} 
                            placeholder={_lang_checkout.inputs_users.input_last_name} 
                            type="text" 
                            id="billing-last-name" 
                            name="billing_last_name" 
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small">
                          <input 
                            value={input_email} 
                            onChange={(e) => set_input_email(e.target.value)}
                            placeholder={_lang_checkout.inputs_users.input_email} 
                            type="text" id="billing-email" 
                            name="billing_email" 
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row split">
                      <div className="form-item">
                        <div className="form-input small">
                          {(payment_method ===_lang_checkout.payment_methods.pix_asaas.payment_id || 
                          payment_method ===_lang_checkout.payment_methods.pix_mercado_pago.payment_id ||
                          _lang_base.lang_base === "pt_BR") 
                          
                          && 
                          <input 
                            value={input_cpf} 
                            onChange={(e) => {
                              let newCpf  = e.target.value;
                              newCpf      = newCpf.replace(/\D/g, '');
                              if (newCpf.length <= 11) {
                                newCpf = newCpf.replace(/(\d{3})(\d)/, '$1.$2');
                                newCpf = newCpf.replace(/(\d{3})(\d)/, '$1.$2');
                                newCpf = newCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
                              }
                              set_input_cpf(newCpf);
                            }}
                            placeholder={_lang_checkout.inputs_users.input_cpf} 
                            type="text" 
                            maxLength="14"
                            id="billing-cpf" 
                            name="billing_email" 
                          />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(payment_method === "5") && component_payment_credit}
            </div>
            <div className="grid-column">
              <div className="sidebar-box">
                <p className="sidebar-box-title">{_lang_checkout.sidebar_title}</p>
                <div className="sidebar-box-items">
                  <div className="totals-line-list separator-bottom">{component_products_cart}</div>
                  <p className="price-title big separator-bottom">{gen_coin_type(value_total, _lang_base.currency)}</p>
                  
                  <div className="totals-line-list">
                    <div className="totals-line">
                      <p className="totals-line-title">Cart ({products_cart.length})</p>
                      <p className="price-title">{gen_coin_type(value_total_no_discont, _lang_base.currency)}</p>
                    </div>
                    {(CouponsInfo !== undefined) && 
                    <div className="totals-line"> 
                      <p className="totals-line-title totals-line-title-two"><span onClick={remove_coupon_discont}><CreateSvg svgname="svg_delete" svgclassname="icon-delete" /></span> Code: {CouponsInfo.coupon_code}</p>
                      <p className="price-title">-{type_discont(CouponsInfo)}</p>
                    </div>}
                    <div className="totals-line">
                      <p className="totals-line-title">Total</p>
                      <p className="price-title">{gen_coin_type(value_total, _lang_base.currency)}</p>
                    </div>
                  </div>

                </div>
                <p className="sidebar-box-title">{_lang_checkout.sidebar_payment_type}</p>
                <div className="sidebar-box-items">
                  <div className="form">{component_payment_method}</div>
                  <div className="form_accetp_terms">
                    <FormControl
                      required
                      error={accept_terms_error}
                      component="fieldset"
                      sx={{ m: 3 }}
                      variant="standard"
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={accept_terms} onChange={accept_terms_checked} name="gilad" />}
                          label={_lang_checkout.check_accept_terms}
                        />
                      </FormGroup>
                      {accept_terms_error && <FormHelperText>{_lang_checkout.accept_terms_error}</FormHelperText>}
                    </FormControl>
                  </div>
                  <p onClick={function_checkout} className="button primary">{_lang_checkout.btn_payment}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout;
