import {useContext }                        from "react";
import Context                              from "../../Provider/Context";

function PopUpLive(props) {
  const { PopUps, SocialLinks }             = useContext(Context);

  return (props.open_pop_up_ads &&
    <div 
      className="bottom-right notify do-show" 
      style={{
        background: `url('${props.background_img}') center center / cover no-repeat`,
        width: "300px", 
        height: "300px"
      }}
      onClick={() => {
        window.open(SocialLinks.link_live_tiktok, '_blank');
      }}
    >
      <div onClick={(e) => {
        e.stopPropagation();
        props.set_open_pop_up_ads(false);
        PopUps.Update({ tiktok_live: { date: new Date(), show: false } });
      }} className="bar-close"><span className="btn-close-pop">X</span></div>
    </div>
  )
}

export default PopUpLive;
