import { useState, useEffect, createRef } from "react";

function EffectAutoEscrita(props) {
  const [animateNovidades, atuAnimateNovidades]    = useState(false);
  const referenciaNovidades                        = createRef();
  const velocidade_animacao                        = 0.0;
  const distancia_para_animacao                    = 100;

  useEffect(() => {
    function handleScroll() {
      const windowPos = window.innerHeight;
      let divNovidades = referenciaNovidades.current;
      if(referenciaNovidades.current !== null) divNovidades = divNovidades.getBoundingClientRect();
      if (divNovidades !== null && (divNovidades.y - distancia_para_animacao) <= windowPos) atuAnimateNovidades(true);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [referenciaNovidades])

  return (
    <span ref={referenciaNovidades} className="sub-title tg__animate-text">
      {(animateNovidades) && props.texto.split("").map((item, index) => 
        <span key={index} style={{animationDelay: `${(index / 10) + velocidade_animacao}s`}}>{item}</span>)}
    </span>
  );
}

export default EffectAutoEscrita;

