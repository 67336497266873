const _lang_login    = {
  title_login: "LOGIN",
  esqueceu_senha: "Forgot your password?",
  msg_bemvindo: "Welcome",
  context_login: "Not a user yet? Register now, it's quick and easy",
  inputs_login: {
    login: "Email or Username",
    senha: "Password",
  },

  title_registrar: "CREATE ACCOUNT",
  msg_title: "Already have an account?",
  context_registrar: "Log in now with your account",
  inputs_registrar: {
    user_name: "Username",
    email: "Email",
    senha: "Password",
    confirmar_senha: "Confirm Password"
  },

  buttons_geral: {
    registrar_btn: "REGISTER",
    login_btn: "LOGIN",
  },


  login_error: "Invalid username or password",
  login_success: "Logged in successfully",

  registro_sucesso: "User created successfully",
  dados_invalidos: "Invalid data",
  usuario_invalido: "Invalid username or password",
  usuario_existe: "Username already exists",
  email_existe: "Email already exists",
  contactar_administrador: "Contact an administrator",

  registrar_errors: {
    confirmar_senha: "Passwords do not match",
    email_invalido: "Invalid email",
    senha_curta: "Password is too short",
    usuario_curto: "Username is too short"
  }
}

module.exports = { _lang_login };
