import { useState, useContext }              from "react";
import { useSnackbar }                       from 'notistack';
import Context                               from "../Provider/Context";
import SYNCHRONIZE_AUTH                      from '../Utils/AUTH/SYNCHRONIZE_AUTH';
import { EXIT_ACCOUNT }                      from "../Utils/UserFunction";

export const useSynchronizeAccount = () => {
  const [backdrop_open, set_backdrop_open]            = useState(false);
  const { enqueueSnackbar }                           = useSnackbar();
  const { UserInfoBase }                              = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  const synchronizeAccount = async () => {
    try {
      set_backdrop_open(true);
      const result = await SYNCHRONIZE_AUTH(UserInfo.user);
      set_backdrop_open(false);

      if (result.success) {
        enqueueSnackbar(result.status_code_message, { variant: 'success' });
        delete result.status_code_message;
        delete result.success;
        delete result.status_code;

        UserInfoBase.Update(result);
      } else {
        enqueueSnackbar(result.status_code_message, { variant: 'error' });
      }
    } catch (error) {
      set_backdrop_open(false);
      EXIT_ACCOUNT();
    }
  };

  return { synchronizeAccount, backdrop_open, set_backdrop_open };
};
