const { _get_storage, storage_vars, _save_storage } = require('../Storage/Storage');

const pt_BR = require("./pt-br");
const en    = require("./en");

const languageCode = (navigator === undefined || 
  navigator.language     === undefined) ? "en" : "pt-BR";

if(_get_storage(storage_vars.language) === null ||
   _get_storage(storage_vars.language) === undefined) {
    _save_storage(storage_vars.language, languageCode);
}

const idiomaSelecionado = (_get_storage(storage_vars.language) === "pt-BR" ||
                           _get_storage(storage_vars.language) === null ||
                           _get_storage(storage_vars.language) === undefined) ? pt_BR : en;

module.exports = idiomaSelecionado;
