const _lang_terms_privacy = {
  title_header: "Termos de Privacidade",
  breadcrumb_item: "Home",
  sub_title: "LEIA ESTA LICENÇA CUIDADOSAMENTE ANTES DE USAR ESTE SOFTWARE.",
  title: "ACORDO DE LICENÇA DE USUÁRIO FINAL",
  informacacoes: [
    {
      titulo: "",
      texto: `Ao receber, abrir os arquivos e/ou usar o produto, concordas que este Contrato de Licença de Usuário Final (EULA) é um contrato válido e concorda em ficar vinculado a ele. concordas em respeitar as leis de propriedade intelectual e todos os termos e condições deste Contrato.
      Sujeito aos termos deste Contrato, a empresa concede uma licença limitada, não exclusiva e intransferível, sem o direito de sublicenciar, de usar o produto de acordo com este Contrato e qualquer outro contrato por escrito com a empresa. A empresa não transfere o título de empresa para ti. Este acordo é um acordo legal vinculativo entre a empresa e os compradores ou usuários da dos produtos.
      Se não concordas em ficar vinculado por este acordo, remova os produtos do seu computador.”`,
    },
    {
      titulo: "DISTRIBUIÇÃO",
      texto: `A empresa, a licença, a conta aqui criada e tudo que for adquirido nela não serão copiadas, compartilhadas, distribuídas, revendidas, oferecidas para revenda, transferidas ou sublicenciadas, no todo ou em parte. Para obter informações sobre a redistribuição dos produtos, contate a equipe da empresa.`,
    },
    {
      titulo: "ACORDO DE USUÁRIO",
      texto: `A licença para usar o produto está limitada ao número de licenças adquiridas por ti. Não deves permitir que outros usem a sua conta.
      Qualquer falha no cumprimento dos termos e condições deste Contrato resultará no cancelamento automático e imediato desta licença. Mediante o término desta licença concedida neste documento por qualquer motivo, se concordas em cessar imediatamente o uso do produto.`,
    },
    {
      titulo: "ISENÇÃO DE GARANTIA",
      texto: `Este software e os arquivos que acompanham são vendidos 'como estão' e sem garantias de desempenho ou de comerciabilidade ou qualquer outra garantia, expressa ou implícita. esta isenção de responsabilidade preenche todos os arquivos gerados e editados pela empresa.`,
    },
    {
      titulo: "RELACIONADO A PROVA",
      texto: `Este software e os arquivos que acompanham são vendidos 'como estão' e sem garantias de desempenho ou de comerciabilidade ou qualquer outra garantia, expressa ou implícita. esta isenção de responsabilidade preenche todos os arquivos gerados e editados pela empresa.
      A empresa não assina nenhuma responsabilidade com as contas como resultado do uso deste site ou de qualquer software ou dados contidos nele. o usuário, é o único responsável por suas atividades de jogos online e qualquer perda de qualquer uma das suas contas, 
      financeiras ou não, é da tua responsabilidade. Embora façamos todos os esforços para garantir que permaneças indetectado por qualquer software anti-mod, nem sempre podemos garanti-lo. Quaisquer 'banimentos de jogo', 
      ocorridos durante a duração da sua licença são de sua exclusiva responsabilidade, se concordas que a empresa não pode ser responsabilizada por tal evento e que tal ocorrência não garante o reembolso da empresa desde que o produto ainda está funcional.
      `,
    },
    {
      titulo: "DEVOLUÇÕES",
      texto: `A empresa só efetuará o reembolso do produto caso sigas a Política de Reembolso em até 2 horas após a compra.`,
    },
    {
      titulo: "RELACIONADO AO PROCEDIMENTO",
      texto: `Para qualquer dúvida enviar um email para suporte@projectoficial.com`,
    }
  ],
}

module.exports = { _lang_terms_privacy };
