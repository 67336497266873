import { AUTH }                                       from "../../Api/Conexao";
import { _lang_base }                                 from "../../Languages/languages";
import { verify_token_expired }                       from "../UserFunction";

const CANCELORDER_AUTH = async (current_user) => {
  try {
    const data = new URLSearchParams();
    data.append('token',  current_user.user.user_token_product);
    data.append('order_id', current_user.order_id);
    const request = await AUTH.post('/routes/cancel_order.php', data).then((response) => response.data);
    request.status_code_message = _lang_base.requests[request.status_code]
    return request;
  } catch (error) {
    verify_token_expired(error.response.data.status_code);
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default CANCELORDER_AUTH;
