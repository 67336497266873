const _lang_product_ui  = {
  product_properties: {
    game: "GAME",
    status: "STATUS",
    type: "TIPO",
    platform: "PLATAFORMA",
    compatible: "COMPATÍVEL",
  },
  btn_video: "VIDEO",
  label_favorite: "Favorite",
  label_report_problem: "Relatar Problema",
  text_btn_inject: "Verificar o Loader",
  products_reports: {
    "0": {
      msg: "Ativado com Sucesso",
      type: "success",
      typeError: "",
      variant: "success"
    },
    "1003": {
      msg: "Seu HWID está diferente",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "1004": {
      msg: "Seu acesso está pausado",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "1005": {
      msg: "Seu acesso acabou",
      type: "error",
      typeError: "compra",
      variant: "error"
    },
    "1006": {
      msg: "Você não tem acesso a este produto",
      type: "error",
      typeError: "compra",
      variant: "error"
    },
    "1007": {
      msg: "Sem acesso a este produto",
      type: "error",
      typeError: "compra",
      variant: "error"
    },
    "1008": {
      msg: "Você tem que recarregar",
      type: "error",
      typeError: "carregar",
      variant: "error"
    },
    "1010": {
      msg: "Ainda não acabou",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "1080": {
      msg: "Seu HWID está bloqueado",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "3002": {
      msg: "Hora do PC inválida",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "4002": {
      msg: "Acesso ao mod desativado", 
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "10": {
      msg: "Loader Fechado", 
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "11": {
      msg: "Verificando", 
      type: "carregando",
      typeError: "suporte",
      variant: "carregando"
    },
  },
}

module.exports = { _lang_product_ui };
