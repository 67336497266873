import { useState, useContext, useEffect }        from "react";
import { useNavigate  }                           from "react-router-dom";

import CreateSvg                                  from "../../Components/Common/CreateSvg";
import LoadingComponent                           from "../../Components/Common/LoadingComponent";

import Context                                    from "../../Provider/Context";

import { gen_coin_type }                          from "../../Utils/StoreFunction";

/* IMPORT IMAGES */
import marketplace_icon                           from "../../assets/img/icons/market_place/marketplace_icon.png";

/* IMPORT LANGUAGE */
import { _lang_store, _lang_base }                from "../../Languages/languages";

function Store() {
  const history                                   = useNavigate();
  const { Products }                              = useContext(Context);
  const product_info                              = Products.vip.filter((item) => item.product_enable_store === 1);
  const [loading_products, set_loading_products]  = useState(true);

  useEffect(() => {setTimeout(() => set_loading_products(false) , 1000)}, []);

  //#region Variaveis de Busca
  const [filter_name, set_filter_name]           = useState("");
  const [filter_product, set_filter_product]     = useState(true);
  const [category_product, set_category_product] = useState("0");
  const [order_product, set_order_product]       = useState("1");
  const quant_products                           = product_info.length;

  const category_list = [
    {
      title: _lang_store.category_list.title_date,
      value: "0",
    },
    {
      title: _lang_store.category_list.title_price,
      value: "1",
    },
  ]

  const order_list    = [
    {
      title: _lang_store.order_list.title_descendente,
      value: "0",
    },
    {
      title: _lang_store.order_list.title_ascendente,
      value: "1",
    },
  ]

  let filter_products       = product_info.filter((item) => (item.product_information !== undefined 
    && item.product_information.product_title !== undefined 
    && item.product_information.product_title.toLowerCase().includes(filter_name)));

  const gen_category_list   = category_list.map((item, index) => 
    <option key={`catgory_list_${index}`}value={item.value}>{item.title}</option>);

  const order_category_list = order_list.map((item, index)    => 
    <option key={`order_list${index}`} value={item.value}>{item.title}</option>);

  switch(category_product) {
    case "0":
      filter_products = filter_products.slice().sort((a, b) => 
        (order_product === "1") ? 
          new Date(a.product_date) - new Date(b.product_date) : 
          new Date(b.product_date) - new Date(a.product_date));
      break;
    case "1":
      filter_products = filter_products.slice().sort((a, b) => 
        (order_product === "1") ? 
          a.product_information.product_discont - b.product_information.product_discont : 
          b.product_information.product_discont - a.product_information.product_discont);
      break;
    default:
      filter_products = filter_products.slice().sort((a, b) => 
        (order_product === "1") ? 
          new Date(a.product_date) - new Date(b.product_date) : 
          new Date(b.product_date) - new Date(a.product_date));
      break;
  }

  //#endregion Variaveis de busca

  const component_product_list_loading                = filter_products.map((_item, index) => 
    <div style={{display: (loading_products) ? "block" : "none"}} key={`loading_product_list_${index}`} className="product-preview product-preview-v2 loading-product">
      <LoadingComponent />
    </div>
  );

  const component_product_list                        = filter_products.map((item, index) => (item.product_category_id === 1) ? 
    <div style={{display: (loading_products) ? "none" : "block"}} onClick={() => history(`${item.product_slug}`)} key={`product_list_${index}`} className="product-preview product-preview-v2">
        <img className="img_product" src={item.product_base_store_img} alt={_lang_base.web_site_name} />
      <div className="product-preview-meta product-preview-meta-v2">
        <div className="name_product">{item.product_information.product_title}</div>
        <div className="value_discont">
          <span className="discont">{gen_coin_type(item.product_information.product_value, _lang_base.currency)}</span>
          <span className="valor">{gen_coin_type(item.product_information.product_discont, _lang_base.currency)}</span>
        </div>
      </div>
    </div>
    :
    <div style={{display: (loading_products) ? "none" : "block"}} onClick={() => history(`${item.product_slug}`)} key={`product_list_${index}`} className="product-preview">
      <figure className="product-preview-image liquid" 
        style={{background: `url('${item.product_base_store_img}') center center / cover no-repeat`}}>
      </figure>
      <div className="product-preview-info">
        <p className="product-preview-title">{item.product_information.product_title}</p>
        <p className="product-preview-category digital">{item.product_information.product_category}</p>
        <p className="product-preview-text">{item.product_information.product_description.substring(0, 68)}</p>
      </div>
      <div className="product-preview-meta product-preview-meta-v2">
        <div className="name_product">{item.product_information.product_title}</div>
        <div className="value_discont">
          <span className="discont">{gen_coin_type(item.product_information.product_value, _lang_base.currency)}</span>
          <span className="valor">{gen_coin_type(item.product_information.product_discont, _lang_base.currency)}</span>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className="container profile">
        <div className="content-grid">
          <div className="section-banner">
            <img className="section-banner-icon" src={marketplace_icon} alt={_lang_base.web_site_name} />
            <p className="section-banner-title">{_lang_store.section_title}</p>
          </div>
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">{_lang_store.section_pretitle}</p>
              <h2 className="section-title">{_lang_store.section_info_title}</h2>
            </div>
            <div className="section-header-actions">
              <p className="section-header-subsection">{_lang_store.section_subsection_title}</p>
              <p className="section-header-subsection">{_lang_store.section_subsection_pretitle}</p>
            </div>
          </div>
          <div className="section-filters-bar v4">
            <div className="section-filters-bar-actions">
              <div className="form">
                <div className="form-item split">
                  <div className="form-input small">
                    <input 
                      onChange={({ target }) => set_filter_name(target.value.toLowerCase())} 
                      placeholder={_lang_store.input_items_search}
                      type="text" 
                      id="items-search" 
                      name="items_search"
                      value={filter_name}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section-filters-bar-actions">
              <div className="form">
                <div className="form-item split medium">
                  <div className="form-select form-select-v2 small">
                    <label htmlFor="items-filter-category">{_lang_store.label_filter_category}</label>
                    <select value={category_product} 
                      id="items-filter-category" 
                      name="items_filter_category" 
                      onChange={({ target }) => set_category_product(target.value)}
                    >
                      {gen_category_list}
                    </select>
                      <CreateSvg svgname="svg_small_arrow" svgclassname="form-select-icon icon-small-arrow" />
                  </div>
                  <div className="form-select form-select-v2 small">
                    <label htmlFor="items-filter-order">{_lang_store.label_filter_order}</label>
                    <select value={order_product} 
                      id="items-filter-order" 
                      name="items_filter_order" 
                      onChange={({ target }) => set_order_product(target.value)}
                    >
                      {order_category_list}
                    </select>
                      <CreateSvg svgname="svg_small_arrow" svgclassname="form-select-icon icon-small-arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-3-9 small-space">
            <div className="marketplace-sidebar">
              <div className="sidebar-box">
                <p className="sidebar-box-title">{_lang_store.siderbar_title}</p>
                <div className="sidebar-box-items">
                  <div className="checkbox-line">
                    <div className="checkbox-wrap">
                      <input type="checkbox" 
                        id="category-all" 
                        name="category_all" 
                        checked={filter_product} 
                        onChange={() => set_filter_product(true)}
                      />
                      <div className="checkbox-box"></div>
                      <label htmlFor="category-all">{_lang_store.check_box_category_all}</label>
                    </div>
                    <p className="checkbox-line-text">{quant_products}</p>
                  </div>
                </div>
                <p className="button small secondary">{_lang_store.btn_aply_filter_by_category}</p>
              </div>
            </div>
            <div className="marketplace-content">
              <div className="grid grid-3-3-3 centered">{component_product_list_loading}{component_product_list}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Store;
