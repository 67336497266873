const _lang_cart = {
  section_title: "Carrinho",
  section_pretitle: "Navegue pelo seu",
  section_info_title: "Carrinho de compras",
  table_sections: {
    table_header_item: "Item",
    table_header_quantity: "Quantidade",
    table_header_price: "Preço",
  },
  total_orders: "Totais de pedidos",
  btn_finalize_purchases: "Finalizar compra",
  text_discont_message: "Se você tiver um código promocional ou de desconto, insira-o aqui para resgatá-lo!",
  btn_discont: "Resgatar código",
}

module.exports = { _lang_cart };
