import { useContext }                                from 'react';
import wappIcon                                      from "../../assets/img/icons/whapp.png";
import Context                                       from "../../Provider/Context";
import { _lang_base }                                from "../../Languages/languages";

const WhatsappWidget = () => {
  const { SocialLinks }                   = useContext(Context);

  return (
    <>
      <div className="floating-wapp enter">
        <div onClick={() => window.open(SocialLinks.link_whatsapp,'_blank')} id="open-chat">
          <i id="icon-chat"></i>
          <img id="img-chat-ballon" src={wappIcon} alt={_lang_base.web_site_name} />
        </div>
      </div>
    </>
  )
}

export default WhatsappWidget;
