const _lang_requests = {
  "error": "Contacte um administrador",
  "not_logged_in": "Você não está logado",

  "447": "Conta sincronizada com sucesso",
  "501": "Credenciais invalidas",
  "502": "Logado com sucesso",

  "701": "Você não pode recarregar",
  "704": "Recarregado com sucesso",

  "600": "Usuário com caracteres inválidos",
  "601": "Usuário muito curto",
  "602": "Usuário muito longo",
  "610": "Senha muito curta",
  "611": "Senha muito longa",
  "612": "Senhas diferentes",
  "603": "E-mail inválido",
  "604": "E-mail inválido",
  "605": "O e-mail já está em uso",
  "606": "Usuário já está em uso",
  "607": "Error ao criar conta",
  "608": "Conta criada com sucesso",

  "613": "Primeiro nome inválido",
  "614": "Segundo nome inválido",
  "615": "Cpf inválido",

  "801": "Key inválida",
  "806": "Key ativada com sucesso",

  "410": "Informações muito curta",
  "411": "Error ao reportar problema",
  "412": "Problema reportado com sucesso",

  "906": "Error ao gerar seu pedido",
  "907": "Pedido gerado com sucesso",
  "914": "Transação não autorizada",
  "915": "Cartão inválido",
  "916": "Data do cartão inválida",
  "917": "Titular do cartão inválido",
  "918": "CVV do cartão inválido",
  "919": "Cupom está inválido",
  "920": "Cupom está expirado",
  "921": "Cupom já foi usado",
  "922": "Cupom ativado com sucesso",
  "925": "Pagamento feito com sucesso",
  "926": "Pedido cancelado com sucesso",

  "300": "E-mail inválido",
  "303": "Informações invalidas",
  "304": "Gere um novo código",
  "309": "E-mail enviado com sucesso, verifique sua caixa de e-mails",
  "310": "Senha alterada com sucesso",

  "913": "Pedidos sincronizados",

  "311": "Discord invalido",
  "312": "Não foi possível vincular",
  "313": "Não foi possível vincular",
  "320": "Discord vinculado com sucesso",
}

module.exports = _lang_requests;
