const _lang_menus = {
  not_have_notifications: "No notifications available.",
  cart_title: "Cart:",
  cart_total_title: "Total:",
  btn_cart: "Cart",
  btn_finalize_purchase: "Finalize Purchase",
  notication_title: "Notifications",
  btn_brand_everything_as_read: "Mark All as Read",
  btn_configuration: "Settings",
  btn_see_all_notifications: "See All Notifications",

  user_header_btn: {
    profile: "Profile",
    orders: "Orders",
    settings: "Settings",
    logout: "Logout"
  }
};

module.exports = { _lang_menus };
