import { useState, useContext, useEffect, useRef }   from "react";
import { useNavigate  }                              from "react-router-dom";
import ReCAPTCHA                                     from "react-google-recaptcha";

import { useSnackbar }                               from 'notistack';
import Backdrop                                      from '@mui/material/Backdrop';
import CircularProgress                              from '@mui/material/CircularProgress';

import LOGIN_AUTH                                    from "../../Utils/AUTH/LOGIN_AUTH";
import REGISTER_AUTH                                 from "../../Utils/AUTH/REGISTER_AUTH";

import Context                                       from "../../Provider/Context";
import { SOCKET_ANALYTICS }                          from "../../Socket/Conexao";

/* IMPORT LANGUAGE */
import { _lang_login, _lang_base }                   from "../../Languages/languages";

function Login() {
  const { UserInfoBase }                              = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;

  const history                                       = useNavigate();
  const recaptchaRef                                  = useRef();

  const { enqueueSnackbar }                           = useSnackbar();
  const [backdrop_open, set_backdrop_open]            = useState(false);

  const [ativarRegistro, atuAtivarRegistro]           = useState(false);

  //LOGIN
  const [user_login, set_user_login]                  = useState("");
  const [password, set_password]                      = useState("");

  //REGISTRAR
  const [reg_user_login, set_reg_user_login]          = useState("");
  const [reg_user_email, set_reg_user_email]          = useState("");
  const [reg_password, set_reg_password]              = useState("");
  const [reg_confi_password, set_reg_confi_password]  = useState("");

  useEffect(() => { if (UserInfo) history("/ui"); }, [UserInfo, history]);

  const login_function = async (event) => {
    try {
      event.preventDefault();
      set_backdrop_open(true);
      const rpf                = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_LOGIN_AUTH  = await LOGIN_AUTH({user_login, password, rpf});
      recaptchaRef.current.reset();
      set_backdrop_open(false);
      if(RESULT_LOGIN_AUTH.success) {
        enqueueSnackbar(RESULT_LOGIN_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_LOGIN_AUTH.status_code_message;
        delete RESULT_LOGIN_AUTH.success;
        delete RESULT_LOGIN_AUTH.status_code;

        UserInfoBase.Update(RESULT_LOGIN_AUTH);
        return;
      }
      enqueueSnackbar(RESULT_LOGIN_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  const register_function = async (event) => {
    try {
      event.preventDefault();
      set_backdrop_open(true);
      const rpf                   = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_REGISTER_AUTH  = await REGISTER_AUTH({ 
        user_login: reg_user_login, 
        password: reg_password, 
        confi_password: reg_confi_password, 
        user_email: reg_user_email, 
        rpf 
      });
      recaptchaRef.current.reset();
      set_backdrop_open(false);
      if(RESULT_REGISTER_AUTH.success) {
        enqueueSnackbar(RESULT_REGISTER_AUTH.status_code_message, {variant: 'success'});
        atuAtivarRegistro(!ativarRegistro);
        SOCKET_ANALYTICS.emit('usuario_registrado', { user:  { user_email: reg_user_email, user_login: reg_user_login } });
        return;
      }
      enqueueSnackbar(RESULT_REGISTER_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
    }
  }

  return(
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
      />
      
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <main>
        <div className="container-login-register">
          <div className={(ativarRegistro) ? "container-login right-panel-active" : "container-login"}>

            <div className="form-container sign-up-container">
              <form>
                <h1>{_lang_login.title_registrar}</h1>
                <div className="social-container"></div>
                <input onChange={(e) => set_reg_user_login(e.target.value)} value={reg_user_login} type="text" placeholder={_lang_login.inputs_registrar.user_name} />
                <input onChange={(e) => set_reg_user_email(e.target.value)} value={reg_user_email} type="email" placeholder={_lang_login.inputs_registrar.email} />
                <input onChange={(e) => set_reg_password(e.target.value)} value={reg_password} type="password" placeholder={_lang_login.inputs_registrar.senha} />
                <input onChange={(e) => set_reg_confi_password(e.target.value)} value={reg_confi_password} type="password" placeholder={_lang_login.inputs_registrar.confirmar_senha} />
                <button onClick={register_function}>{_lang_login.buttons_geral.registrar_btn}</button>
              </form>
            </div>

            <div className="form-container sign-in-container">
              <form onSubmit={login_function}>
                <h1>{_lang_login.title_login}</h1>
                <div className="social-container"></div>
                <input type="text" onChange={(e) => set_user_login(e.target.value)} value={user_login} placeholder={_lang_login.inputs_login.login} />
                <input type="password" onChange={(e) => set_password(e.target.value)}  value={password} placeholder={_lang_login.inputs_login.senha} />
                <div onClick={() => history("/lostpassword")} className="esqueceu-senha">{_lang_login.esqueceu_senha}</div>
                <button>{_lang_login.buttons_geral.login_btn}</button>
              </form>
            </div>

            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>{_lang_login.msg_title}</h1>
                  <p>{_lang_login.context_registrar}</p>
                  <button onClick={() => atuAtivarRegistro(!ativarRegistro)} className="ghost">{_lang_login.buttons_geral.login_btn}</button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>{_lang_login.msg_bemvindo}</h1>
                  <p>{_lang_login.context_login}</p>
                  <button onClick={() => atuAtivarRegistro(!ativarRegistro)} className="ghost">{_lang_login.buttons_geral.registrar_btn}</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
    </>
  )
}

export default Login;
