import { _lang_popupvideo }                           from "../../Languages/languages";
import { useSnackbar }                                from 'notistack';

function PopUpVideo(props) {
  const { enqueueSnackbar }                           = useSnackbar();

  return (props.open_pop_up_ads &&
    <div 
      className="bottom-right notify do-show" 
      style={{width: "360px", height: "260px"}}
    >
    <iframe 
        className="bottom-right-pop-up" 
        src={`${props.youtube_link}?autoplay=1`} 
        title="YouTube video player" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen 
      />
      <div onClick={(e) => { 
        e.stopPropagation();
        props.set_open_pop_up_ads(false);
        window.open(props.youtube_link.replace('embed/', 'watch?v='), '_blank');
        enqueueSnackbar(_lang_popupvideo.message_ads, {variant: 'success'});
      }} className="bar-close-youtube"><span className="btn-close-pop">X</span></div>
    </div>
  )
}

export default PopUpVideo;
