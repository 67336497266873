const _lang_terms_of_use = {
  breadcrumb_item: "Home",
  title: "Termos de Uso",
  sub_title: "SIGA TODAS AS REGRAS",
  title_sub: "REGRAS",
  context: "Para termos um ambiente bom e uma comunidade agradável para todos precisamos seguir algumas regras!!",
  informacacoes: [
    {
      titulo: "Proibido qualquer tipo de preconceito",
      texto: `Qualquer opinião ou sentimento concebido sem exame crítico.
      sentimento hostil, assumido em consequência da generalização apressada de uma experiência pessoal ou imposta pelo meio; intolerância.
      “p. contra um grupo religioso, nacional ou racial”`,
    },
    {
      titulo: "Proibido qualquer tipo de conteúdo pornográfico",
      texto: `Estudo da prostituição. coleção de pinturas ou gravuras obscenas.`,
    },
    {
      titulo: "Proibido a postagem de vírus/malwares",
      texto: `Um código malicioso, programa malicioso, software nocivo, software mal-intencionado ou software malicioso, é um programa de computador destinado a infiltrar-se em um sistema de computador alheio de forma ilícita, com o intuito de causar alguns danos, alterações ou roubo de informações.`,
    },
    {
      titulo: "Política de compra",
      texto: `Assim que efetuar a compra do software você tem ate 2 horas para pedir o reembolso usando ou não o software, depois desse tempo não será feito o reembolso.
      O reembolso só e emitido caso for feito todos os testes possíveis para funcionar no PC do usuário caso de nenhuma forma funcionar e feito o reembolso.`,
    },
    {
      titulo: "Nós não somos responsáveis pela segurança da sua conta...",
      texto: `Nos garantimos o melhor serviço em todos os nossos mods e bypass , mas o risco e total do usuário , caso vc seja penalizado pelo jogo não adianta reclamar com nos ADMINISTRADORES ou ficar reclamando em chats. Lembre-se que ninguém obrigou você a usar os mods.`,
    },
    {
      titulo: "Você é responsável pela segurança de sua conta",
      texto: `Se você perder ou for hackeado e responsabilidade do usuário cuidar da própria conta , então tome cuidado .`,
    },
    {
      titulo: "Você é responsável pelo conteúdo publicado na sua conta",
      texto: `Toda e qualquer penalidade feita pela sua conta será responsabilidade sua , e qualquer medida como banimentos ou suspensões não será reivindicados.`,
    },
    {
      titulo: "Você não pode registrar mais de uma (1) conta no nosso site",
      texto: `Buscamos manter nosso controle de membros , então pedimos e colocamos essa regra para que não criem mais de uma conta e mantenha sua conta pessoal. `,
    },
    {
      titulo: "Respeite a privacidade alheia, não compartilhe informações...",
      texto: `Não compartilhe ou poste informações pessoais no site ou no discord isso resultara no banimento imediato , o grupo não foi formado para isso. `,
    },
    {
      titulo: "Sem publicidade, marketing ou promoção de qualquer tipo",
      texto: `A publicidade é uma atividade profissional dedicada à difusão pública de empresas, produtos ou serviços. Pode ser qualificada como uma “propaganda comercial”. É a divulgação de produtos, serviços, e ideias junto ao público, tendo, em vista, induzi-lo a uma atitude dinâmica favorável. `,
    },
    {
      titulo: "Proibido spam nos nossos chats/calls e na dm dos nossos membros ou no site",
      texto: `SPAM é a prática que consiste em utilizar meios eletrônicos para enviar mensagens que não foram solicitadas. Em geral, o objetivo do SPAM é fazer propaganda de produtos e serviços, mas também aplicar golpes, disseminar boatos e espalhar softwares maliciosos. Seu nome é baseado em um esquete do Monty Python.`,
    },
    {
      titulo: "Use os canais de forma certa",
      texto: `Usar os canais de formas certas e postar na área certa , usar área de duvidas com responsabilidade sem quebrar nenhuma outra regra.`,
    },
    {
      titulo: "Proibido qualquer tipo de conteúdo gore",
      texto: `Splatter ou gore é um subgênero do cinema de terror que, deliberadamente, se concentra em representações gráficas de sangue e violência. Estas películas, por meio da utilização de efeitos especiais, tendem a apresentar um interesse evidente na vulnerabilidade do corpo humano e na sua teatral mutilação.`,
    },
  ]  
}

module.exports = { _lang_terms_of_use };
