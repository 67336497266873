import { useNavigate  }                               from "react-router-dom";
import { _lang_login_embed }                         from "../../Languages/languages";

function LoginEmbed() {
  const history                                       = useNavigate();

  return(
    <section className="services__details-area section-pt-120 section-pb-120 checkout-section">        
      <div className="center-button">
        <div className="centralizar-textos">
          <div><h1>{_lang_login_embed.msg_login_redirect}</h1></div>
          <div onClick={() => history('/login')} className="tg-btn-1"><span>{_lang_login_embed.btn_login_redirect}</span></div>
        </div>
      </div>
    </section>
  )
}

export default LoginEmbed;
