const _lang_home = {
  slider__area: {
    header_text: `MAXIMIZE YOUR CHANCES OF VICTORY WITH OUR MODS.`,
    header_btn: "LEARN MORE",
  },
  about__area: {
    about__funFact: "IT WAS NEVER LUCK IT WAS ALWAYS",
    title: "NOW YOU CAN ALSO BE A PRO PLAYER",
    about__content: `If you're an avid gamer, you know how important it is to have all the necessary tools to maximize your gaming experience. Our game mods system is the perfect solution for players looking to enhance their gameplay and gain a competitive edge.`,
    textPath: "quality guaranteed",
    info_serve: {
      members: "MEMBERS",
      mods: "MODS",
      years: "YEARS"
    },
    about_btn: {
      about_us: "ABOUT US"
    },
  },
  streamers__area: {
    header_text: "ALL OUR MODS",
    animate_text: "Daily updates",
  },
  project_area: {
    header_text: "ALL THE LATEST FROM PROJECT",
    animate_text: "stay informed",
  },
  social_area: {
    header_text: "stay always connected",
    animate_text: "connect with the project",
  }
};

module.exports = { _lang_home };
